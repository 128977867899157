import React from "react";
import "./Navbar.css";
import logo from "../Images/Logo.png";
import Notification from "../SideNotification/Notification";
import LogoutModal from "../LogoutModal/LogoutModal";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
const Navbar = () => {
  return (
    <div className="navBar">
      <div className="main">
        <div className="first">
          <Link to="/AdminHome">
            <img src={logo} alt="logo" />
            <h4>MYCENTRALITY</h4>
          </Link>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Modules
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Link to="/NewFeed">Add new feed</Link>
              <Link to="/RecycleBin">Recycle bin/ Archive</Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="last">
          <span>
            <i></i>
            <Notification />
          </span>
          <LogoutModal />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
