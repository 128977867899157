import React, { useState, useEffect } from "react";
import Logo from "../Images/Logo.png";
import SideImg1 from "../Images/image 9.png";
import SideImg2 from "../Images/Group 1000006269.png";
import SideImg3 from "../Images/cloud-computing.png";
import { useSelector } from "react-redux";
import axios from "axios";
import "./SignUpPage.css";
import { Country, State } from "country-state-city";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "react-country-state-city/dist/react-country-state-city.css";
import { setUser } from "../../redux/reducers/userReducer";
import { baseUrl } from "../../constants";
axios.defaults.baseURL = baseUrl;
const images = [SideImg1, SideImg2, SideImg3];

const SignUpPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const { user } = useSelector((state) => state.auth);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const countryStates = State.getStatesOfCountry(selectedCountry);
      setStates(countryStates);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setSelectedState("");
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const getDotClass = (index) => {
    return index === currentImageIndex ? "dits-white" : "dits-blue";
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [districtId, setDistrictId] = useState("");
  // const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const handleSignup = async (e) => {
    e.preventDefault();
    const nameRegex = /^[A-Z][a-zA-Z-' ]+$/;
    const phoneNumberRegex =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})(?:[\s.-]?\d{3})(?:[\s.-]?\d{4})$/;

    if (!nameRegex.test(firstName) || !nameRegex.test(lastName))
      return toast.error("First and last name must be valid");

    if (!phoneNumberRegex.test(phoneNumber))
      return toast.error("Phone number isn't an American number");
    if (password.length < 8)
      return toast.error("Password must be atleast 8 characters");
    const toastId = toast.loading("Loading...");

    try {
      const { data } = await axios.post(
        `/superAdmins/signup`,
        {
          firstName,
          lastName,
          email,
          password,
          phoneNumber,
          districtId: selectedState || "districtId-2983",
          country: selectedCountry || "America",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Signed Up", {
        id: toastId,
      });
      localStorage.setItem(" superadmintoken", data?.accessToken);
      dispatch(setUser(data?.superAdmin));
      navigate("/AdminHome");
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.details?.[0] || error?.response?.data?.message,
        {
          id: toastId,
        }
      );
    }
  };

  return (
    <div className="Addsignup-main">
      <div className="Addsignup-main-child-1">
        <div className="Addsignup-main-child-1-logo">
          <div className="LogoSuper">
            <img src={Logo} alt="signinimg" />
          </div>
          <h2>MyCentrality</h2>
        </div>
        <div className="Addsignup-main-child-1-img">
          <img src={images[currentImageIndex]} alt="img" />
        </div>
        <div className="Addsignup-main-child-1-taxt">
          <h2>MyCentrality Multipurpose Dashboard</h2>
          <p>Everything you need in an easily customizable dashboard.</p>
        </div>
        <div className="Addsignup-main-child-1-dits">
          {images.map((_, index) => (
            <div key={index} className={getDotClass(index)}></div>
          ))}
        </div>
      </div>
      <div className="Addsignup-main-child-2">
        <div className="SuperAdminAMin">
          <div className="Addsignup-main-child-2-title">
            <h2>Sign up for MyCentrality</h2>
            <p>
              Our District onboarding team will be in touch with next steps to
              create an account.
            </p>
          </div>
          <div className="SuperSignIN">
            <form onSubmit={handleSignup}>
              <div className="SignUpInputs">
                <div className="SignUp">
                  <label>First Name</label>
                  <br />
                  <input
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="SignUp">
                  <label>Last Name</label>
                  <br />
                  <input
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="SignUpInputs">
                <div className="SignUp">
                  <label>Email</label>
                  <br />
                  <input
                    type="email"
                    placeholder="sheryar@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="SignUp">
                  <label>Phone Number</label>
                  <br />
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="SignUpInputs">
                <div className="SignUp">
                  <label>Country</label>
                  <br />
                  <select
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    <option value="">Select a country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedCountry && (
                  <div className="SignUp">
                    <label>Name District/Organization</label>
                    <select value={selectedState} onChange={handleStateChange}>
                      <option value="">Select a District</option>
                      {states.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>

              <div className="SignUp">
                <label>Password</label>
                <br />

                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="LoginContinue">
                Create Super Admin account
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
