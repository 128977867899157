import React, { useRef, useState } from "react";
import { IoIosPeople } from "react-icons/io";
import {
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearchOutline,
} from "react-icons/io5";
import profile from "../../Images/dummy-user.png";
import "./AddAdmin.css";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { useAddDistrictAdminMutation } from "../../../redux/APIs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const AddAdmin = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate("/AdminHome");
  }
  // eslint-disable-next-line
  const { user } = useSelector((state) => state.auth);

  const imageRef = useRef();
  const [profilePicture, setProfilePicture] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // eslint-disable-next-line
  const [districtId, setDistrictId] = useState("");
  console.log(districtId, "I'm district id");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [addDistrictAdminHandler] = useAddDistrictAdminMutation();

  const handleAddDistrictAdmin = async () => {
    const toastId = toast.loading("Adding");
    try {
      if (
        !/^(?:\+?1[-.●]?)?\(?(\d{3})\)?[-.●]?(\d{3})[-.●]?(\d{4})$/.test(
          phoneNumber
        )
      )
        return toast.error("Phone number isn't an American number", {
          id: toastId,
        });
      const data = new FormData();
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      data.append("email", email);
      data.append("phoneNumber", phoneNumber);
      data.append("districtId", districtId);

      data.append("profilePicture", profilePicture);

      const res = await addDistrictAdminHandler(data);

      console.log(res, "Handlersssss");
      if (res.error)
        return toast.error(res?.error?.data?.message || "Failed to add admin", {
          id: toastId,
        });

      toast.success("District administrator added successfully", {
        id: toastId,
      });
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setDistrictId("");
      setProfilePicture(null);

      navigate(`/DistrictAdmins`);
    } catch (error) {}
  };

  return (
    <div className="AddNEwAdminMAin aDDDistrict">
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handleClick}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>Total District Administrators</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>Add District Administrators</h2>
        </div>
      </div>
      <div className="SchoolMTabs">
        <div className="AddNewAdminInputs">
          <img
            onClick={() => imageRef.current?.click()}
            src={profilePicture ? URL.createObjectURL(profilePicture) : profile}
            alt="#"
          />
          <input
            type="file"
            accept="image/*"
            ref={imageRef}
            style={{ display: "none" }}
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
          <div className="AdminInputs">
            <label>First Name</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Name"
            />

            <br />
            <label>Last Name</label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />

            <br />
            <label>District ID</label>
            <input
              value={districtId}
              onChange={(e) => setDistrictId(e.target.value)}
              placeholder="District Id"
            />
          </div>
          <div className="AdminInputs">
            <label>Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <br />
            <label>Phone Number</label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Contact"
            />
            <div className="CreateButton">
              <button className="CreateButton1" onClick={handleClick}>
                Close
              </button>
              <button
                onClick={handleAddDistrictAdmin}
                className="CreateButton2"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Adminnnnnno">
        <div className="AdminDocu">
          <div className="AdminDocTop1">
            <h3>Give Permissions</h3>
            <p>
              Enabling Modules for Admin provides basic Read level of access.
              With the permissions setting below, Sub-Admin users can have
              additional write and delete access.{" "}
            </p>
          </div>
          <div className="AdminBody">
            <div className="AdminAccordion">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    District Administrators permission{" "}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="SchoolATabSearch1">
                      <IoSearchOutline />
                      <input placeholder="Search" />
                    </div>
                    <h3>Full Access:</h3>
                    <div className="AdminSwicth">
                      <div className="AdminTwinSwitch">
                        <p>
                          Access to all modules and features within the
                          MyCentrality SIS.
                        </p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                      <div className="AdminTwinSwitch">
                        <p>
                          Ability to view, add, edit, and delete data across the
                          entire district.
                        </p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                    </div>
                    <hr />
                    <h3>User Management:</h3>
                    <div className="AdminSwicth">
                      <div className="AdminTwinSwitch">
                        <p>Create and manage user accounts and roles.</p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                      <div className="AdminTwinSwitch">
                        <p>
                          Assign specific permissions and access levels to other
                          users.
                        </p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                    </div>
                    <hr />
                    <h3>Data Management:</h3>
                    <div className="AdminSwicth">
                      <div className="AdminTwinSwitch">
                        <p>Manage and manipulate district-wide data.</p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                      <div className="AdminTwinSwitch">
                        <p>
                          Use CSV import/export tools and data integration
                          features.
                        </p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                    </div>
                    <hr />
                    <h3>System Configuration:</h3>
                    <div className="AdminSwicth">
                      <div className="AdminTwinSwitch">
                        <p>Configure and manage system-wide settings.</p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                      <div className="AdminTwinSwitch">
                        <p>Access advanced configuration options and tools.</p>
                        <Form>
                          <Form.Check type="switch" id="discipline-switch" />
                        </Form>
                      </div>
                    </div>
                    <hr />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="CreateButton">
                <button className="CreateButton1">Reset</button>
                <button className="CreateButton2">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
