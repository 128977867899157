import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  error: null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: () => ({
    setUser: (state, action) => {
      state.user = action.payload;
    },
  }),
});

export default authSlice;

export const { setUser } = authSlice.actions;
