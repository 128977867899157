import React, { useRef, useState, useEffect } from "react";
import { IoIosPeople } from "react-icons/io";
import {
  IoCaretForwardOutline,
  IoCloseCircleSharp,
  IoHomeSharp,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./Setting.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { TbEdit } from "react-icons/tb";
import { BiSolidLockAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useUpdateAdminProfileMutation } from "../../redux/APIs";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/reducers/userReducer";
import { useUpdateAdminPasswordMutation } from "../../redux/APIs";
import Avator from "../PickAvator/Avator";
import { baseUrl } from "../../constants";
import Dummy from "../Images/dummy-user.png";
const Setting = () => {
  const navigate = useNavigate();
  function handlenavigate() {
    navigate("/AdminHome");
  }
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [country, setCountry] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const imageRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const [previewImage, setPreviewImage] = useState(null);
  const [updateProfileHandler] = useUpdateAdminProfileMutation();
  const [lgShow, setLgShow] = useState(false);
  const [profilePicture2, setProfilePicture2] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  console.log(profilePicture);
  console.log(previewImage);
  const updateProfile = async () => {
    const nameRegex = /^[A-Z][a-zA-Z-' ]+$/;
    const phoneNumberRegex =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})(?:[\s.-]?\d{3})(?:[\s.-]?\d{4})$/;

    if (!phoneNumberRegex.test(phoneNumber))
      return toast.error("Phone number isn't an American number");

    if (!nameRegex.test(firstName) || !nameRegex.test(lastName))
      return toast.error("First and last name must be valid");

    const toastId = toast.loading("Updating...");
    try {
      const myForm = new FormData();

      myForm.append("firstName", firstName);
      myForm.append("lastName", lastName);
      myForm.append("email", email);
      myForm.append("phoneNumber", phoneNumber);
      myForm.append("districtId", districtId);
      myForm.append("country", country);
      profilePicture2 && myForm.append("profilePicture", profilePicture2);

      const res = await updateProfileHandler({
        adminId: user?.id,
        data: myForm,
      });

      if (res.data?.success) {
        dispatch(setUser(res.data?.user));
        return toast.success("Update profile successfully", {
          id: toastId,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    setFirstName(user?.firstName || "");
    setLastName(user?.lastName || "");
    setEmail(user?.email || "");
    setPhoneNumber(user?.phoneNumber || "");
    setDistrictId(user?.districtId || "");
    setCountry(user?.country || "");
    setPreviewImage(user?.profilePicture);
  }, [user]);

  const [updatePasswordHandler] = useUpdateAdminPasswordMutation();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const changePassword = async () => {
    try {
      if (newPassword !== confirmPassword)
        return toast.error("New password and confirm password isn't matching");

      const toastId = toast.loading("Updating...");
      const data = {
        currentPassword,
        newPassword,
        SuperAdminId: user?.id,
      };

      const res = await updatePasswordHandler({ adminId: user?.id, data });

      if (res?.error)
        return toast.error(
          res?.error?.data?.details?.[0]?.detail || res?.error?.data?.message,
          {
            id: toastId,
          }
        );

      toast.success("Password Updated Successfully", {
        id: toastId,
      });
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handlenavigate}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>My Account</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>My Account</h2>
          <h5 onClick={handlenavigate}>
            Close
            <span>
              <IoCloseCircleSharp />
            </span>
          </h5>
        </div>
      </div>

      <div className="SideTabs">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    <TbEdit />
                    Edit Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <BiSolidLockAlt />
                    Change Password
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9} className="Seventy">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="FullSeventy">
                    <div
                      onClick={() => setLgShow(true)}
                      className="FullProfile"
                    >
                      <Avator
                        lgShow={lgShow}
                        setLgShow={setLgShow}
                        setSelectedFile={setSelectedFile}
                        setProfilePicture={setProfilePicture2}
                      />

                      <img
                        style={{
                          objectFit: "contain",
                        }}
                        src={
                          selectedFile ||
                          `${baseUrl}/images/${user?.profilePicture}`
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.src = Dummy;
                        }}
                        alt="/"
                      />
                      <TbEdit />
                      <input
                        type="file"
                        onChange={(e) => {
                          setPreviewImage(null);
                          setProfilePicture(e.target.files[0]);
                        }}
                        ref={imageRef}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                    </div>
                    <div className="FullInput">
                      <label>First name</label>
                      <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First name"
                      />
                      <br />
                      <label> Last name</label>
                      <input
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last name"
                      />
                      <br />
                      <label>Email Address</label>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                      />
                      <br />
                      <label>Contact</label>
                      <input
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="+923554257804"
                      />
                      <br />
                      <label>District ID</label>
                      <input
                        value={districtId}
                        onChange={(e) => setDistrictId(e.target.value)}
                        placeholder="District ID"
                      />
                      <br />
                      <label>Country</label>
                      <input
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="Country"
                      />
                      <br />
                      <div className="CreateButton">
                        <button
                          className="CreateButton1"
                          onClick={handlenavigate}
                        >
                          Close
                        </button>
                        <button
                          onClick={updateProfile}
                          className="CreateButton2"
                        >
                          Update Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="SeconDFull">
                    <div className="FullInput1">
                      <label>Current Password</label>
                      <input
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        placeholder="Current Password"
                      />
                      <br />
                      <label> New Password</label>
                      <input
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                      />
                      <br />
                      <label>Confirm Password</label>
                      <input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                      />
                      <br />
                      <div className="FullCheckbox">
                        <input type="checkbox" />
                        <label>
                          Logout from all active login sessions (except the
                          current login session in this device)
                        </label>
                      </div>
                      <div className="CreateButton">
                        <button
                          className="CreateButton1"
                          onClick={handlenavigate}
                        >
                          Close
                        </button>
                        <button
                          onClick={changePassword}
                          className="CreateButton2"
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Setting;
