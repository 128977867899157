import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./SchoolTabs.css";
import {
  IoCaretBack,
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearchOutline,
} from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ByDate from "../ByDate/ByDate";
import FilterModal from "../Filter/FilterModal";
import { PiDotsNine, PiKeyFill } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoPlus } from "react-icons/go";
import Dummy from "../Images/dummy-user.png";
import { FaLocationDot } from "react-icons/fa6";
import { TbLockFilled } from "react-icons/tb";
import { MdDelete } from "react-icons/md";
import { FaUserEdit, FaUserLock } from "react-icons/fa";
import AddSchoolinfo from "../SchoolInformation/ADDSchool";
import { RiDragMove2Fill } from "react-icons/ri";
import {
  useGetSchoolAdminsQuery,
  useGetSchoolStudentsQuery,
  useGetSchoolTeachersQuery,
  useGetSchoolQuery,
  useDeleteAdminMutation,
} from "../../redux/APIs";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TeacherCard from "../TeacherCards/TeacherCard";
import { baseUrl } from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SchoolTabs = () => {
  const [searchValue, setSearchValue] = useState("");

  const itemsPerPage = 21;
  const [adminCurrentPage, setAdminCurrentPage] = useState(0);
  const [adminsData, setAdminsData] = useState([]);
  const adminPageCount = Math.ceil(adminsData.length / itemsPerPage);

  const handleAdminsPageClick = ({ selected }) => {
    setAdminCurrentPage(selected);
  };

  const adminOffset = adminCurrentPage * itemsPerPage;
  const currentAdminPageData = adminsData.slice(
    adminOffset,
    adminOffset + itemsPerPage
  );
  const [studentCurrentPage, setStudentCurrentPage] = useState(0);
  const [studentsData, setStudentsData] = useState([]);

  const studentPageCount = Math.ceil(studentsData.length / itemsPerPage);

  const handleStudentsPageClick = ({ selected }) => {
    setStudentCurrentPage(selected);
  };

  const studentOffset = studentCurrentPage * itemsPerPage;

  const currentStudentPageData = studentsData.slice(
    studentOffset,
    studentOffset + itemsPerPage
  );

  const [teacherCurrentPage, setTeacherCurrentPage] = useState(0);
  const [teachersData, setTeachersData] = useState([]);

  const teacherPageCount = Math.ceil(teachersData.length / itemsPerPage);

  const handleTeachersPageClick = ({ selected }) => {
    setTeacherCurrentPage(selected);
  };
  const teacherOffset = teacherCurrentPage * itemsPerPage;

  const currentTeacherPageData = teachersData.slice(
    teacherOffset,
    teacherOffset + itemsPerPage
  );
  const navigate = useNavigate();

  function handleClick() {
    navigate("/AdminHome");
  }

  const [showTable, setShowTable] = useState(true);

  const { schoolId } = useParams();
  const { data: apiAdmins } = useGetSchoolAdminsQuery(
    {
      schoolId,
    },
    {
      skip: !schoolId,
    }
  );

  useEffect(() => {
    if (!searchValue && apiAdmins) {
      setAdminsData(apiAdmins);
      console.log(apiAdmins, "G");
    } else if (searchValue && apiAdmins) {
      setAdminsData(
        apiAdmins.filter(
          (admin) =>
            admin?.firstName
              ?.toLowerCase()
              .includes(searchValue?.toLowerCase()) ||
            admin?.lestName?.toLowerCase().includes(searchValue?.toLowerCase())
        )
      );
    } else {
      setAdminsData([]);
    }
  }, [searchValue, apiAdmins]);

  const { data: apiStudents } = useGetSchoolStudentsQuery(
    {
      schoolId,
    },
    {
      skip: !schoolId,
    }
  );

  const { data: apiTeachers } = useGetSchoolTeachersQuery(
    {
      schoolId,
    },
    {
      skip: !schoolId,
    }
  );

  useEffect(() => {
    if (apiStudents) {
      setStudentsData(apiStudents?.students);
    }
  }, [apiStudents]);

  useEffect(() => {
    if (apiTeachers) {
      setTeachersData(apiTeachers?.teacherList);
    }
  }, [apiTeachers]);
  const [open, setOpen] = React.useState(false);
  const [editId, setEditId] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEditId("");
    setOpen(false);
  };
  const { data: school } = useGetSchoolQuery({ schoolId }, { skip: !schoolId });

  const [checkedItems, setCheckedItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const handleHeaderCheckboxChange = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      setCheckedItems(currentAdminPageData.map((item) => item.id));
    }
    setAllChecked(!allChecked);
  };
  const handleRowCheckboxChange = (id) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };

  useEffect(() => {
    if (editId) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [editId]);

  const [deleteAdminHandler] = useDeleteAdminMutation();

  const handleDeleteSchool = async () => {
    const toastId = toast.loading("Deleting Admin...");
    try {
      const res = await deleteAdminHandler(editId);

      if (res?.error)
        return toast.error(
          res?.error?.data?.message || "Failed to delete admin",
          {
            id: toastId,
          }
        );

      toast.success("Admin deleted successfully", {
        id: toastId,
      });

      handleClose();
      setEditId("");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(currentAdminPageData, "This is Name");
  return (
    <div className="SchoolDash">
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handleClick}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>School</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>{school?.school?.name}</h2>
        </div>
      </div>
      <Tab.Container defaultActiveKey="first" id="left-tabs-example">
        <div className="SchoolMTabs">
          <Nav variant="pills" className="SchoolNav">
            <Nav.Item>
              <Nav.Link eventKey="first">School Admins</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Teacher</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">Student</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">School Info</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="SchoolTAbBody">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="SchoolAdminTab">
                <div className="SchoolATabTop">
                  <div className="SchoolFilter">
                    <div className="SchoolATabSearch">
                      <IoSearchOutline />
                      <input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search"
                      />
                    </div>
                    <ByDate />
                    <FilterModal />
                    <div
                      className="SchoolToggle"
                      onClick={() => {
                        setShowTable(!showTable);
                      }}
                    >
                      <span
                        style={{ backgroundColor: !showTable && "#00afef" }}
                      >
                        <PiDotsNine
                          style={{ color: !showTable ? "white" : "#1E293B" }}
                        />
                      </span>
                      <span style={{ backgroundColor: showTable && "#00afef" }}>
                        <RxHamburgerMenu
                          style={{ color: showTable ? "white" : "#1E293B" }}
                        />
                      </span>
                    </div>
                  </div>
                  <Link to={`/AddNewAdmin/${schoolId}`} className="AddnewAdmin">
                    <GoPlus />
                    Add New
                  </Link>
                </div>
                {showTable && (
                  <div>
                    <div className="SchoolTable">
                      <table>
                        <thead>
                          <tr>
                            <th>S#</th>
                            <th>
                              <input
                                type="checkbox"
                                checked={allChecked}
                                onChange={handleHeaderCheckboxChange}
                              />
                            </th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Address</th>
                            <th>Email</th>
                            <th>Roles</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentAdminPageData.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1 + adminOffset}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={checkedItems.includes(item.id)}
                                  onChange={() =>
                                    handleRowCheckboxChange(item.id)
                                  }
                                />
                              </td>
                              <td>
                                <div className="SAdminProfile">
                                  <img
                                    src={`${baseUrl}/images/${item?.profilePicture}`}
                                    onError={({ currentTarget }) => {
                                      currentTarget.src = Dummy;
                                    }}
                                    alt="Profile"
                                  />
                                  <p>{item?.firstname}</p>
                                </div>
                              </td>
                              <td>
                                <div className="SAdminProfile">
                                  <p> {item?.lestname}</p>
                                </div>
                              </td>
                              <td>
                                <div className="SAdminAddress">
                                  <FaLocationDot />
                                  <p>{item?.address}</p>
                                </div>
                              </td>
                              <td>{item?.email}</td>
                              <td>
                                <div className="AdminRole">
                                  <h3>{item?.role}</h3>
                                </div>
                              </td>
                              <td>
                                <div className="SIcons">
                                  <TbLockFilled />
                                  <RiDragMove2Fill />
                                  <Link
                                    to={`/EditCredential/${schoolId}/${item?.id}`}
                                  >
                                    <FaUserLock />
                                  </Link>
                                  <PiKeyFill />
                                  <FaUserEdit
                                    onClick={() =>
                                      navigate(
                                        `/AddNewAdmin/${schoolId}?editId=${item?.id}`
                                      )
                                    }
                                  />
                                  <MdDelete
                                    onClick={() => setEditId(item?.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <h3 className="ModalHeadd">
                                Are you want to delete this admin?
                              </h3>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <div className="ModalBTNS">
                                <button
                                  className="ModalBtn1"
                                  onClick={handleClose}
                                >
                                  No
                                </button>
                                <button
                                  className="ModalBtn2"
                                  onClick={handleDeleteSchool}
                                >
                                  Yes
                                </button>
                              </div>
                            </Typography>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                    <div>
                      <ReactPaginate
                        previousLabel={<IoCaretBack />}
                        nextLabel={<IoCaretForwardOutline />}
                        breakLabel={"..."}
                        pageCount={adminPageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handleAdminsPageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
                {!showTable && (
                  <div>
                    <div className="TeacherCArdInSch">
                      {currentAdminPageData.map((teacher) => (
                        <TeacherCard
                          status={"admin"}
                          key={teacher.id}
                          teacher={teacher}
                          data={teacher}
                        />
                      ))}
                    </div>

                    <ReactPaginate
                      previousLabel={<IoCaretBack />}
                      nextLabel={<IoCaretForwardOutline />}
                      breakLabel={"..."}
                      pageCount={adminPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handleAdminsPageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="SchoolATabTop">
                <div className="SchoolFilter">
                  <div className="SchoolATabSearch">
                    <IoSearchOutline />
                    <input placeholder="Search" />
                  </div>
                  <ByDate />
                  <FilterModal />
                  {/* <div className="SchoolToggle">
                    <span>
                      <PiDotsNine />
                    </span>
                    <RxHamburgerMenu />
                  </div> */}
                </div>
                <Link to={`/AddNewTeacher/${schoolId}`} className="AddnewAdmin">
                  <GoPlus />
                  Edit Permission
                </Link>
              </div>
              <div className="TeacherCArdInSch">
                {currentTeacherPageData.map((dt) => (
                  <TeacherCard status={"teacher"} key={dt.id} data={dt} />
                ))}
              </div>
              <ReactPaginate
                previousLabel={<IoCaretBack />}
                nextLabel={<IoCaretForwardOutline />}
                breakLabel={"..."}
                pageCount={teacherPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handleTeachersPageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </Tab.Pane>

            <Tab.Pane eventKey="third">
              <div className="SchoolATabTop">
                <div className="SchoolFilter">
                  <div className="SchoolATabSearch">
                    <IoSearchOutline />
                    <input placeholder="Search" />
                  </div>
                  <ByDate />
                  <FilterModal />
                  {/* <div className="SchoolToggle">
                    <span>
                      <PiDotsNine />
                    </span>
                    <RxHamburgerMenu />
                  </div> */}
                </div>
                <Link to={`/StudentPer/${schoolId}`} className="AddnewAdmin">
                  <GoPlus />
                  Edit Permission
                </Link>
              </div>
              <div className="TeacherCArdInSch">
                {currentStudentPageData?.map((data) => (
                  <TeacherCard status={"student"} key={data.id} data={data} />
                ))}
              </div>
              <ReactPaginate
                previousLabel={<IoCaretBack />}
                nextLabel={<IoCaretForwardOutline />}
                breakLabel={"..."}
                pageCount={studentPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handleStudentsPageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <AddSchoolinfo />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default SchoolTabs;
