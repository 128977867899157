import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./Avator.css";
import one from "./Layer_1-212.png";
import two from "./Layer_1-2.png";
import three from "./Layer_1-2 (1).png";
import four from "./Layer_1-2 (2).png";
import five from "./Layer_1-2 (3).png";
import six from "./Layer_1-2 (4).png";
import { LuUploadCloud } from "react-icons/lu";
import seven from "./Layer_1-2 (5).png";
import eight from "./Layer_1-2 (6).png";
import nine from "./Layer_1-2 (7).png";
import ten from "./Layer_1-2 (8).png";
import eleven from "./Layer_1-2 (9).png";
import twelve from "./Layer_1-2 (10).png";
import thirteen from "./Layer_1-2 (11).png";
import fourteen from "./Layer_1-2 (12).png";
import fifteen from "./Layer_1-2 (13).png";
import sixteen from "./Layer_1-2 (14).png";
import seventeen from "./Layer_1-2 (15).png";
import eighteen from "./Layer_1-2 (16).png";
import nineteen from "./Layer_1-2 (17).png";
import twenty from "./Layer_1-2 (18).png";
import twentyone from "./Layer_1-2 (19).png";
import twentytwo from "./Layer_1-2 (20).png";
import twentythree from "./Layer_1-2 (21).png";
import twentyfour from "./Layer_1-2 (22).png";
import twentyfive from "./Layer_1-2 (23).png";
import twentysix from "./Layer_1-2 (24).png";
import twentyseven from "./Layer_1-2 (25).png";
import twentyeight from "./Layer_1-2 (26).png";
import twentynine from "./Layer_1-2 (27).png";
import thirty from "./Layer_1-2 (28).png";
import thirtyone from "./Layer_1-2 (29).png";
import thirtytwo from "./Layer_1-2 (30).png";
import thirtythree from "./Layer_1-2 (31).png";
import thirtyfour from "./Layer_1-2 (32).png";
import toast from "react-hot-toast";
import axios from "axios";
import { baseUrl } from "../../constants";
axios.defaults.baseURL = baseUrl;

function Avator({
  lgShow,
  setLgShow,
  setProfilePicture,
  setSelectedFile: setSlFile,
}) {
  const [isActive, setIsActive] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    setIsActive(true);
    setSelectedFile(e.target.files[0]);
    setSelectedAvatar(e.target.files[0]);
  };

  const handleImageClick = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const [avatars, setAvatars] = useState([
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
    twentyone,
    twentytwo,
    twentythree,
    twentyfour,
    twentyfive,
    twentysix,
    twentyseven,
    twentyeight,
    twentynine,
    thirty,
    thirtyone,
    thirtytwo,
    thirtythree,
    thirtyfour,
  ]);
  console.log(setAvatars);
  const handleUploadImage = async () => {
    try {
      const form = new FormData();
      console.log(form);

      if (selectedAvatar && !isActive) {
        const res = await fetch(selectedAvatar);
        const data = await res.blob();
        setProfilePicture(data);
        setSlFile(selectedAvatar);

        setLgShow(false);
        setIsActive(false);
      } else if (isActive) {
        const reader = new FileReader();

        reader.onloadend = () => {
          setSlFile(reader.result);
        };
        reader.readAsDataURL(selectedAvatar);

        setProfilePicture(selectedAvatar);
        setLgShow(false);
        setIsActive(false);
      }
    } catch (error) {
      console.log(error);
      setIsActive(false);
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details?.[0]?.detail ||
            error?.response?.data?.details?.[0]?.message
        );
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    if (selectedAvatar) {
      handleUploadImage();
    }
    // eslint-disable-next-line
  }, [selectedAvatar]);
  return (
    <div className="Avator-Main">
      <Modal
        size="lg"
        onBackdropClick={() => {
          setLgShow(false);
        }}
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="AvatorModal"
      >
        <h2 id="example-modal-sizes-title-lg">Pick an Avator</h2>
        <hr />

        <Modal.Body>
          <div className="ChildMain">
            <div className="ChildAvator">
              <div className="ChildONEE">
                {selectedFile ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Staff"
                    style={{
                      width: "100%",
                      height: "171px",
                      cursor: "pointer",
                      borderRadius: "50%",
                    }}
                    onClick={handleUploadClick}
                  />
                ) : (
                  <>
                    <LuUploadCloud size={20} onClick={handleUploadClick} />
                    <label
                      htmlFor="upload"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleUploadClick}
                    >
                      Upload
                    </label>
                  </>
                )}

                <input
                  type="file"
                  id="upload"
                  name="profilePicture"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
              {avatars.slice(0, 6).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(6, 13).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(13, 20).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(20, 27).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
            <div className="ChildAvator">
              {avatars.slice(27, 34).map((src, index) => (
                <div
                  key={index}
                  className="avatar-image-container"
                  onClick={() => handleImageClick(src)}
                  style={{ position: "relative" }}
                >
                  <img src={src} alt={`Avatar ${index + 1}`} />
                  {selectedAvatar === src && (
                    <div className="green-tick">✔</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Avator;
