import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../constants";

const userApis = createApi({
  reducerPath: "user",
  tagTypes: [
    "DistrictSchools",
    "Admins",
    "NewsFeedback",
    "DistrictSchoolsTrashbin",
    "DistrictSchoolsAdmin",
    "DistrictAdministrators",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl,
    headers: {
      accessToken: localStorage.getItem("superadmintoken"),
    },
  }),
  endpoints: (builder) => ({
    getTotalDistrictSchools: builder.query({
      query: ({ districtId }) => ({
        url: `/schools/all`,
      }),
      providesTags: ["DistrictSchools", "DistrictSchoolsTrashbin"],
    }),
    getTotalDistrictSchoolsCount: builder.query({
      query: ({ districtId }) => ({
        url: `/schools/getSchoolsByDistrictId/${districtId}`,
      }),
      providesTags: ["DistrictSchools", "DistrictSchoolsTrashbin"],
    }),
    deleteDistrictSchool: builder.mutation({
      query: ({ schoolId }) => ({
        url: `/schools/removeSchool/${schoolId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DistrictSchools", "DistrictSchoolsTrashbin"],
    }),
    getSchoolAdmins: builder.query({
      query: ({ schoolId }) => ({
        url: `/newAdmins/getNewAdminBySchoolId/${schoolId}`,
      }),
      providesTags: [
        "Admins",
        "DistrictSchoolsAdmin",
        "DistrictAdministrators",
      ],
    }),

    getSchoolStudents: builder.query({
      query: ({ schoolId }) => ({
        url: `/students/all/${schoolId}`,
      }),
    }),
    getSchoolTeachers: builder.query({
      query: ({ schoolId }) => ({
        url: `/teachers/all/${schoolId}`,
      }),
    }),
    getSchoolParents: builder.query({
      query: ({ schoolId }) => ({
        url: `/parents/getParents/${schoolId}`,
      }),
    }),
    getSchool: builder.query({
      query: ({ schoolId }) => ({
        url: `/schools/getSchoolById/${schoolId}`,
      }),
    }),
    updateAdminProfile: builder.mutation({
      query: ({ adminId, data }) => ({
        url: `/superAdmins/updateSuperAdmin/${adminId}`,
        method: "PUT",
        body: data,
      }),
    }),

    addAdmin: builder.mutation({
      query: ({ myForm }) => ({
        url: `/NewAdmins/addNewAdmin`,
        method: "POST",
        body: myForm,
      }),
      invalidatesTags: ["Admins"],
    }),

    updateAdminById: builder.mutation({
      query: ({ myForm, adminId }) => ({
        url: `/NewAdmins/updatenewAdmin/${adminId}`,
        method: "PUT",
        body: myForm,
      }),
      invalidatesTags: ["Admins"],
    }),

    addNewsFeedback: builder.mutation({
      query: (data) => ({
        url: `/superAdminFeedbacks/feedback`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["NewsFeedback"],
    }),
    editNewsFeedback: builder.mutation({
      query: ({ feedbackId, data }) => {
        return {
          url: `/superAdminFeedbacks/updateFeedback/${feedbackId}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["NewsFeedback"],
    }),

    deleteNewsFeedback: builder.mutation({
      query: (feedbackId) => ({
        url: `/superAdminFeedbacks/deleteFeedback/${feedbackId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NewsFeedback"],
    }),
    getNewsFeedback: builder.query({
      query: ({ adminId }) => ({
        url: `/superAdminFeedbacks/getFeedbackBySuperAdminId/${adminId}`,
      }),
      providesTags: ["NewsFeedback"],
    }),
    getAdminsByDistrictId: builder.query({
      query: ({ districtId }) => ({
        url: `/admins/getAdminsByDistrictId/${districtId}`,
      }),
      providesTags: ["Admins"],
    }),
    getDistrictAdministrators: builder.query({
      query: ({ districtId }) => ({
        url: `/Admins/getAllAdmins/${districtId}`,
      }),
      providesTags: ["Admins"],
    }),

    restoreDistrictAdministrators: builder.mutation({
      query: (data) => ({
        url: `/Admins/restoreAdmin`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Admins"],
    }),

    destroyRecycleAdministrators: builder.mutation({
      query: (data) => ({
        url: `/admins/destroyAdmins`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Admins"],
    }),
    getAdminById: builder.query({
      query: ({ adminId }) => ({
        url: `/newAdmins/getnewadminById/${adminId}`,
      }),
    }),

    updateAdminPassword: builder.mutation({
      query: ({ adminId, data }) => ({
        url: `/superAdmins/updateSuperAdminPassword/${adminId}`,
        method: "POST",
        body: data,
      }),
    }),
    updateTeachersPermissions: builder.mutation({
      query: (data) => ({
        url: `/teacherPermissions/create`,
        method: "POST",
        body: data,
      }),
    }),
    addAdminPermissions: builder.mutation({
      query: (data) => ({
        url: `/permission/addpermissions`,
        method: "POST",
        body: data,
      }),
    }),
    deleteAdmin: builder.mutation({
      query: (adminId) => ({
        url: `/NewAdmins/deleteNewAdmin/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admins"],
    }),
    deleteDistrictAdministrators: builder.mutation({
      query: (adminId) => ({
        url: `/admins/destroyAdminone/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Admins"],
    }),
    updateStudentPermissions: builder.mutation({
      query: (data) => ({
        url: `/studentPermissions/create`,
        method: "POST",
        body: data,
      }),
    }),
    uploadSchoolsCSV: builder.mutation({
      query: (data) => ({
        url: `/schools/bulkAddSchools`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["DistrictSchools"],
    }),
    uploadBulkCSV: builder.mutation({
      query: (data) => ({
        url: `/Admins/bulkAddAdmins`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["DistrictSchools", "Admins"],
    }),
    lockSchool: builder.mutation({
      query: (schoolId) => ({
        url: `/schools/lockschool/${schoolId?.lockSchoolId}`,
        method: "PUT",
        body: {
          islocked: schoolId?.lockSchool,
        },
      }),
      invalidatesTags: ["DistrictSchools"],
    }),
    changeSchoolStatus: builder.mutation({
      query: ({ schoolId, status }) => ({
        url: `/schools/lockschool/${schoolId}`,
        method: "PUT",
        body: {
          active: status,
        },
      }),
      invalidatesTags: ["DistrictSchools"],
    }),

    updateAdmin: builder.mutation({
      query: ({
        firstName,
        newPassword,
        logoutAllSessions,
        sendNotificationEmail,
        changePassword,
        email,
        adminId,
      }) => ({
        url: `/newAdmins/updateAdmin/${adminId}`,
        method: "PUT",
        body: {
          firstName,
          newPassword,
          logoutAllSessions,
          sendNotificationEmail,
          changePassword,
          email,
        },
      }),
    }),
    // Something has to change in this api
    addDistrictAdmin: builder.mutation({
      query: (data) => ({
        url: `/Admins/signup`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Admins"],
    }),
    getDistrictSchoolsTrashbin: builder.query({
      query: ({ districtId }) => ({
        url: `/schools/getSchoolsAndStudentsByDistrictId/${districtId}`,
      }),
      providesTags: ["DistrictSchoolsTrashbin"],
    }),
    deleteSchoolFromTrashbin: builder.mutation({
      query: ({ schoolId }) => ({
        url: `/schools/destroyschool/${schoolId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DistrictSchoolsTrashbin"],
    }),
    deleteDistrictSchoolAdminFromTrashbin: builder.mutation({
      query: ({ adminId }) => ({
        url: `/newAdmins/destroyAdminsone/${adminId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DistrictSchoolsAdmin"],
    }),
    getDistrictSchoolsAdminsTrashbin: builder.query({
      query: ({ districtId }) => ({
        url: `/newAdmins/getSchoolsAndAdminsByDistrictId/${districtId}`,
      }),
      providesTags: ["DistrictSchoolsAdmin"],
    }),
    trashbinLockAdministrator: builder.mutation({
      query: ({ islock, adminId }) => ({
        url: `/Admins/updateLockStatus/${adminId}`,
        method: "PUT",
        body: {
          islock,
        },
      }),
      invalidatesTags: ["Admins"],
    }),
    trashbinLockSchoolAdmin: builder.mutation({
      query: ({ islock, adminId }) => ({
        url: `/newAdmins/updatelock/${adminId}`,
        method: "PUT",
        body: {
          islock,
        },
      }),
      invalidatesTags: ["DistrictSchoolsAdmin"],
    }),
    destroyDistrictSchoolsTrashbin: builder.mutation({
      query: (schoolIds) => ({
        url: `/schools/destroyschool`,
        method: "DELETE",
        body: {
          schoolIds,
        },
      }),
      invalidatesTags: ["DistrictSchoolsTrashbin"],
    }),
    restoreSchoolsAdminsFromTrashbin: builder.mutation({
      query: (adminIds) => ({
        url: `/newAdmins/restoreAdmins`,
        method: "PUT",
        body: {
          newadminIds: adminIds,
        },
      }),
      invalidatesTags: ["DistrictSchoolsAdmin", "Admins"],
    }),
    restoreDistrictSchoolsFromTrashbin: builder.mutation({
      query: (schoolIds) => ({
        url: `/schools/restoreSchool`,
        method: "PUT",
        body: {
          schoolIds,
        },
      }),
      invalidatesTags: [
        "DistrictSchoolsTrashbin",
        "DistrictSchoolsAdmin",
        "DistrictSchools",
      ],
    }),
    destroySchoolAdminsFromTrashbin: builder.mutation({
      query: (newadminIds) => ({
        url: `/newAdmins/destroyAdmin`,
        method: "DELETE",
        body: {
          newadminIds,
        },
      }),
      invalidatesTags: [
        "Admins",
        "DistrictSchoolsAdmin",
        "DistrictAdministrators",
      ],
    }),
    lockNewDistrictAdmin: builder.mutation({
      query: ({ islock, adminId }) => ({
        url: `/admins/updateLockStatus/${adminId}`,
        method: "PUT",
        body: {
          islock,
        },
      }),
      invalidatesTags: [
        "Admins",
        "DistrictSchoolsAdmin",
        "DistrictAdministrators",
      ],
    }),
  }),
});

export const {
  useGetTotalDistrictSchoolsQuery,
  useDeleteDistrictSchoolMutation,
  useGetTotalDistrictSchoolsCountQuery,
  useGetSchoolAdminsQuery,
  useGetSchoolStudentsQuery,
  useGetSchoolTeachersQuery,
  useGetSchoolParentsQuery,
  useGetSchoolQuery,
  useUpdateAdminProfileMutation,
  useAddAdminMutation,
  useGetNewsFeedbackQuery,
  useAddNewsFeedbackMutation,
  useEditNewsFeedbackMutation,
  useDeleteNewsFeedbackMutation,
  useUpdateAdminPasswordMutation,
  useUpdateTeachersPermissionsMutation,
  useAddAdminPermissionsMutation,
  useGetAdminPermissionsQuery,
  useGetDistrictAdministratorsQuery,
  useDeleteAdminMutation,
  useUpdateStudentPermissionsMutation,
  useUploadSchoolsCSVMutation,
  useUpdateAdminMutation,
  useGetAdminsByDistrictIdQuery,
  useLockSchoolMutation,
  useGetDistrictSchoolsTrashbinQuery,
  useDeleteSchoolFromTrashbinMutation,
  useGetDistrictSchoolsAdminsTrashbinQuery,
  useUpdateAdminByIdMutation,
  useGetAdminByIdQuery,
  useAddDistrictAdminMutation,
  useUploadBulkCSVMutation,
  useDeleteDistrictAdministratorsMutation,
  useDeleteDistrictSchoolAdminFromTrashbinMutation,
  useRestoreDistrictAdministratorsMutation,
  useDestroyRecycleAdministratorsMutation,
  useTrashbinLockAdministratorMutation,
  useTrashbinLockSchoolAdminMutation,
  useDestroyDistrictSchoolsTrashbinMutation,
  useRestoreSchoolsAdminsFromTrashbinMutation,
  useRestoreDistrictSchoolsFromTrashbinMutation,
  useDestroySchoolAdminsFromTrashbinMutation,
  useLockNewDistrictAdminMutation,
  useChangeSchoolStatusMutation,
} = userApis;
export { userApis };
