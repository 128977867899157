import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import {
  IoCaretBack,
  IoCaretForwardOutline,
  IoHomeSharp,
} from "react-icons/io5";
import Total from "./Total";
import Pagination from "./Pagination";
import ReactPaginate from "react-paginate";
import { MdDelete } from "react-icons/md";
import { RiDragMove2Fill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import toast from "react-hot-toast";
import Dummy from "../Images/dummy-user.png";
import {
  useGetTotalDistrictSchoolsQuery,
  useLockSchoolMutation,
  useDeleteDistrictSchoolMutation,
  useChangeSchoolStatusMutation,
} from "../../redux/APIs";
import { useSelector } from "react-redux";
import { baseUrl } from "../../constants";
import moment from "moment";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SwitchContainer = styled.div`
  display: inline-block;
  width: 50px;
  height: 23px;
  position: relative;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
`;
const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.checked ? props.onColor : props.offColor};
  transition: 0.4s;
  border-radius: 34px;
  pointer-events: none;
  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props) =>
      props.checked ? "translateX(26px)" : "translateX(0)"};
  }
`;

const Switch = ({
  isChecked,
  onToggle,
  onColor = "#E34444",
  offColor = "#00AFEF",
}) => {
  return (
    <SwitchContainer onClick={onToggle}>
      <SwitchInput type="checkbox" checked={isChecked} readOnly />
      <Slider
        checked={isChecked}
        onColor={onColor}
        offColor={offColor}
      ></Slider>
    </SwitchContainer>
  );
};

const Dashboard = () => {
  const [searchValue, setSearchValue] = useState("");

  const { user } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);

  const { data: districtSchools } = useGetTotalDistrictSchoolsQuery(
    {
      districtId: user?.districtId,
    },
    {
      skip: !user?.districtId,
    }
  );
  console.log(districtSchools, "dasgdsags");

  useEffect(() => {
    if (!searchValue && districtSchools) {
      setData(districtSchools?.schoolDetails);
    } else if (searchValue && districtSchools) {
      const filteredData = districtSchools?.schoolDetails.filter((school) =>
        school.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setData(filteredData);
    } else {
      setData([]);
    }
  }, [districtSchools, searchValue]);
  console.log(districtSchools, "Containers");
  // eslint-disable-next-line
  // const [activeStatus, setActiveStatus] = useState("In progress");
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };
  const [modalText, setModalText] = useState(
    "Are you want to lock this school?"
  );

  const offset = currentPage * itemsPerPage;
  const currentPageData = data?.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data?.length / itemsPerPage);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isShow, setIsShow] = useState(false);
  console.log(handleOpen);

  useEffect(() => {
    if (isShow) {
      setOpen(true);
    }
  }, [isShow]);

  const [schoolId, setSchoolId] = useState("");

  const [schoolDeleteHandler] = useDeleteDistrictSchoolMutation();
  const handleDeleteSchool = async () => {
    const toastId = toast.loading("Deleting...");
    try {
      const res = await schoolDeleteHandler({ schoolId });
      if (res.error)
        return toast.error(res?.error?.data?.message, {
          id: toastId,
        });

      toast.success("Deleted", {
        id: toastId,
      });
      setOpen(false);
    } catch (error) {
      console.log(error, "I'm error");
    }
  };

  const [currentDate, setCurrentDate] = useState(new Date());

  const [lockSchoolId, setLockSchoolId] = useState({
    lockSchoolId: "",
    lockSchool: "",
  });

  const [lockSchoolHandler] = useLockSchoolMutation();
  // eslint-disable-next-line
  const [isChecked, setIsChecked] = useState("");

  const handleLockSchool = async () => {
    try {
      const res = await lockSchoolHandler(lockSchoolId);
      if (res?.error) return toast.error("Failed to lock");
      toast.success(res?.data?.islocked ? "Locked" : "Unlocked");
      handleClose();
    } catch (error) {}
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = daysOfWeek[currentDate.getDay()];
  const date = currentDate.getDate();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  const [changeStatusHandler] = useChangeSchoolStatusMutation();

  const changeActiveStatus = async (schoolId, status) => {
    try {
      const res = await changeStatusHandler({ schoolId, status });
      if (res.error) return toast.error("Failed to change status");
      toast.success(`Status updated to ${status}`);
    } catch (error) {}
  };
  return (
    <>
      <div className="backgroundColor">
        <div className="home">
          <div className="mainHome">
            <div className="main">
              <IoHomeSharp />
              <p>Home</p>
            </div>
          </div>
          <div className="mainDash">
            <p>Dashboard</p>
          </div>
        </div>
        <div className="profileView">
          <div className="main">
            <div className="mainProfile">
              <div className="name">
                <h2>
                  Hey {user?.firstName} {user?.middleName} {user?.lastName} 👋🏼,
                </h2>

                <div className="imgInfo">
                  <img
                    src={`${baseUrl}/images/${user?.profilePicture}`}
                    onError={({ currentTarget }) => {
                      currentTarget.src = Dummy;
                    }}
                    alt="profile"
                  />
                  <div className="nameInfo">
                    <p>District Name: {user?.districtId}</p>
                    <p>Country: {user?.country}</p>
                  </div>
                </div>
              </div>
              <div className="view">
                <Link to="/AccountSetting">
                  <button>View Profile</button>
                </Link>
                <p>
                  <span>{day}</span>
                  <br /> {date}th {month} {year}
                </p>
              </div>
            </div>
            <div className="total">
              <Total />
            </div>
          </div>
        </div>
        <div className="Pagination">
          <Pagination
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </div>
        <div className="DashTable">
          <div>
            <div className="SchoolTable">
              <table>
                <thead>
                  <tr>
                    <th>S#</th>
                    <th>School Name</th>
                    <th>Address</th>
                    <th>Time Period</th>
                    <th>Teachers</th>
                    <th>Student</th>
                    <th>Parents</th>
                    <th>Admins</th>
                    <th>UnLock/Lock</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1 + offset}</td>
                      <td>
                        <Link to={`/SchoolTabs/${item?.schoolId}`}>
                          {item.name}
                        </Link>
                      </td>
                      <td>
                        <div className="SAdminAddress">
                          <FaLocationDot />
                          <p>
                            {item?.address1}, {item?.address2}
                          </p>
                        </div>
                      </td>
                      <td>
                        {moment(item?.openedOn).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td>{item?.totalTeachers}</td>
                      <td>{item?.totalStudents}</td>
                      <td>{item?.totalParents}</td>
                      <td>{item?.totalAdmins}</td>
                      <td>
                        <Switch
                          isChecked={item?.islocked}
                          onToggle={() => {
                            setIsChecked(item?.schoolId);
                            setModalText("Are you want to lock this school?");
                            setLockSchoolId({
                              lockSchoolId: item?.schoolId,
                              lockSchool: !item?.islocked,
                            });
                            setOpen(true);
                          }}
                          onColor="#E34444"
                          offColor="#00AFEF"
                        />
                      </td>
                      <td>
                        <select
                          onChange={(e) =>
                            changeActiveStatus(item?.schoolId, e.target.value)
                          }
                          style={{
                            backgroundColor:
                              item?.active === "inprogress"
                                ? "#11A529"
                                : item?.active === "inactive"
                                ? "#E34444"
                                : item?.active === "upcoming"
                                ? "#FD8E1F"
                                : "gray",
                          }}
                        >
                          <option
                            selected={item?.active === "inprogress"}
                            value="inprogress"
                          >
                            In progress
                          </option>
                          <option
                            selected={item?.active === "inactive"}
                            value="inactive"
                          >
                            Inactive
                          </option>
                          <option
                            selected={item?.active === "upcoming"}
                            value="upcoming"
                          >
                            Upcoming
                          </option>
                        </select>
                      </td>
                      <td>
                        <div className="SIcons">
                          <RiDragMove2Fill />
                          <MdDelete
                            onClick={() => {
                              setModalText(
                                "Are you want to delete this school?"
                              );
                              setSchoolId(item?.schoolId);
                              setOpen(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <ReactPaginate
                previousLabel={<IoCaretBack />}
                nextLabel={<IoCaretForwardOutline />}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <h3 className="ModalHeadd">{modalText}</h3>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="ModalBTNS">
                    <button
                      className="ModalBtn1"
                      onClick={() => {
                        handleClose();
                        if (modalText === "Are you want to lock this school?") {
                          setIsShow(false);
                        }
                      }}
                    >
                      No
                    </button>
                    <button
                      className="ModalBtn2"
                      onClick={() => {
                        handleClose();
                        if (
                          modalText === "Are you want to delete this school?"
                        ) {
                          handleDeleteSchool();
                        } else {
                          handleLockSchool();
                        }
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
