import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children, user, loading, redirect = "/" }) => {
  const navigate = useNavigate();

  if (!user && !loading) navigate(redirect);

  return children;
};

export default ProtectedRoute;
