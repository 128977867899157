import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./Keyboard.css";
import profile from "../Images/Vector.png";

const Keyboard = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="LogoutModal">
        <div className="mainModal">
          <div className="Logo" onClick={handleShow}>
            <img
              style={{
                objectFit: "cover",
                cursor: "pointer",
              }}
              src={profile}
              alt="#"
            />
          </div>

          <div className="modalContainer">
            <Modal
              show={show}
              onHide={handleClose}
              animation={false}
              className="custom-modall"
            >
              <Modal.Header>
                <div className="MyProfile_linkk">
                  <Modal.Title classNasme="title">
                    <h4 className="KeyboardModalH4">
                      Select the title Which You need to show{" "}
                    </h4>
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body className="detlas">
                <div className="ByDate-main">
                  <div className="container Bydate">
                    <div className="row">
                      <div className="col-md-6 checkboxs">
                        <input type="checkbox" />
                        <label>Address</label>
                      </div>
                      <div className="col-md-6 checkboxs">
                        <input type="checkbox" />
                        <label>Time Period</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 checkboxs">
                        <input type="checkbox" />
                        <label>Teachers</label>
                      </div>
                      <div className="col-md-6 checkboxs">
                        <input type="checkbox" />
                        <label>Admins</label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 checkboxs">
                        <input type="checkbox" />
                        <label>Students</label>
                      </div>
                      <div className="col-md-6 checkboxs">
                        <input type="checkbox" />
                        <label>Parents</label>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="FIlter-Btn">
                        <button>Done</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Keyboard;
