import React, { useState, useRef } from "react";
import { IoIosPeople } from "react-icons/io";
import {
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearchOutline,
} from "react-icons/io5";
import profile from "../Images/dummy-user.png";
import "./AddAdmin.css";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
  useAddAdminMutation,
  useAddAdminPermissionsMutation,
  useUpdateAdminByIdMutation,
  useGetAdminByIdQuery,
} from "../../redux/APIs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { baseUrl } from "../../constants";
const AddAdmin = () => {
  const [allPermissions, setAllPermissions] = useState(true);
  const [onlyOne, setOnlyOne] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [discipline, setDiscipline] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [dataAnalysis, setDataAnalysis] = useState(false);
  const [security, setSecurity] = useState(false);
  const [counselingRecords, setCounselingRecords] = useState(false);
  const [academicPlanning, setAcademicPlanning] = useState(false);
  const [studentSupport, setStudentSupport] = useState(false);
  const [districtWideData, setDistrictWideData] = useState(false);
  const [policyOversight, setPolicyOversight] = useState(false);
  const [strategicPlanning, setStrategicPlanning] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [integration, setIntegration] = useState(false);
  const [curriculumManagement, setCurriculumManagement] = useState(false);
  const [assessment, setAssessment] = useState(false);
  const [professionalDevelopment, setProfessionalDevelopment] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [dataAccess, setDataAccess] = useState(false);
  const [IEPmanagement, setIEPManagement] = useState(false);
  const [compliance, setCompliance] = useState(false);
  const [dataCollection, setDataCollection] = useState(false);
  const [collaboration, setCollaboration] = useState(false);
  const [languageProficiency, setLanguageProficiency] = useState(false);
  const [ELLProgrammeManagement, setELLProgrammeManagement] = useState(false);
  const [reporting, setReporting] = useState(false);
  const [updateAdminHandler] = useUpdateAdminByIdMutation();

  const resetForm = () => {
    setDiscipline(true);
    setScheduling(true);
    setDataAnalysis(true);
    setSecurity(true);
    setCounselingRecords(true);
    setAcademicPlanning(true);
    setStudentSupport(true);
    setDistrictWideData(true);
    setPolicyOversight(true);
    setStrategicPlanning(true);
    setUserManagement(true);
    setIntegration(true);
    setCurriculumManagement(true);
    setAssessment(true);
    setProfessionalDevelopment(false);
    setCommunication(true);
    setDataAccess(true);
    setIEPManagement(true);
    setCompliance(true);
    setDataCollection(true);
    setCollaboration(true);
    setLanguageProficiency(true);
    setELLProgrammeManagement(true);
    setReporting(true);
  };
  const navigate = useNavigate();
  const params = useParams();

  function handleClick() {
    navigate("/AdminHome");
  }
  function handleClickS() {
    navigate(`/SchoolTabs/${params.schoolId}`);
  }

  const [addAdminHandler] = useAddAdminMutation();
  const { schoolId } = useParams();

  const editId = useSearchParams()[0].get("editId");
  // eslint-disable-next-line
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const imageRef = useRef();
  const [profilePicture, setProfilePicture] = useState(null);

  const { data: getAdmin } = useGetAdminByIdQuery(
    { adminId: editId },
    { skip: !editId }
  );

  const [isPreview, setIsPreview] = useState(null);

  useEffect(() => {
    if (getAdmin) {
      setFirstName(getAdmin?.firstname || "");
      setLastName(getAdmin?.lastname || "");
      setContact(getAdmin?.contact || "");
      setAddress(getAdmin?.address || "");
      setEmail(getAdmin?.email || "");
      setIsPreview(getAdmin?.profilePicture || null);
      setSelectedOption(getAdmin?.role);
    }
  }, [getAdmin]);
  console.log(editId, "This is edit id");
  const handleSubmit = async () => {
    const toastId = toast.loading(editId ? "Editing..." : "Adding...");
    const nameRegex = /^[A-Z][a-zA-Z-' ]+$/;

    const phoneNumberRegex =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (!nameRegex.test(firstname))
      return toast.error("Name must be valid", {
        id: toastId,
      });

    if (!phoneNumberRegex.test(contact))
      return toast.error("Contact number isn't an American number", {
        id: toastId,
      });
    const myForm = new FormData();
    myForm.append("firstname", firstname);
    myForm.append("lestname", lastname);
    myForm.append("contact", contact);
    myForm.append("address", address);
    myForm.append("email", email);
    myForm.append("role", selectedOption);
    myForm.append("districtid", user?.districtId);
    myForm.append("profilePicture", profilePicture);
    myForm.append("schoolId", schoolId);

    try {
      if (!editId) {
        const res = await addAdminHandler({ myForm, adminId: editId });
        if (res.error)
          return toast.error(
            res?.error?.data?.details?.[0]?.detail || res?.error?.data?.message,
            {
              id: toastId,
            }
          );
        setFirstName("");
        setLastName("");
        setContact("");
        setAddress("");
        setEmail("");
        setSelectedOption("");
        setProfilePicture(null);
        navigate(`/SchoolTabs/${schoolId}`);
        toast.success("Admin Added Successfully", {
          id: toastId,
        });
      } else {
        const res = await updateAdminHandler({ myForm, adminId: editId });

        console.log(res, "Edit response");
        if (res?.error) {
          return toast.error(
            res?.error?.data?.details?.[0]?.detail || res?.error?.data?.message,
            {
              id: toastId,
            }
          );
        }
        toast.success("Admin Updated Successfully", {
          id: toastId,
        });
      }
      setFirstName("");
      setLastName("");
      setContact("");
      setAddress("");
      setEmail("");
      setSelectedOption("");
      setProfilePicture(null);
      navigate(`/SchoolTabs/${schoolId}`);
      toast.success("Admin Updated Successfully", {
        id: toastId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [addAdminPermissionsHandler] = useAddAdminPermissionsMutation();
  const handleSaveAdminPermissions = async () => {
    try {
      const res = await addAdminPermissionsHandler({
        discipline,
        scheduling,
        dataAnalysis,
        security,
        counselingRecords,
        academicPlanning,
        studentSupport,
        districtWideData,
        policyOversight,
        strategicPlanning,
        userManagement,
        integration,
        curriculumManagement,
        assessment,
        professionalDevelopment,
        communication,
        dataAccess,
        compliance,
        dataCollection,
        collaboration,
        languageProficiency,
        reporting,
        superadminid: user?.id,
      });

      if (res?.error)
        return toast.error(
          res?.error?.data?.details?.[0]?.detail || res?.error?.data?.message
        );

      toast.success("Permissions Updated Successfully");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    resetForm();
  }, [onlyOne, selectedOption, allPermissions]);
  return (
    <div className="AddNEwAdminMAin">
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handleClick}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>Manage Admins</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>Add Admin</h2>
        </div>
      </div>
      <div className="SchoolMTabs">
        <div className="AddNewAdminInputs">
          <img
            onClick={() => imageRef.current?.click()}
            src={
              isPreview
                ? `${baseUrl}/images/${isPreview}`
                : profilePicture
                ? URL.createObjectURL(profilePicture)
                : profile
            }
            alt="#"
          />
          <input
            type="file"
            accept="image/*"
            ref={imageRef}
            style={{ display: "none" }}
            onChange={(e) => {
              setIsPreview(null);
              setProfilePicture(e.target.files[0]);
            }}
          />
          <div className="AdminInputs">
            <label>First Name</label>
            <input
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
            />
            <br />
            <label>Contact</label>
            <input
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              placeholder="Contact"
            />
            <br />
            <div className="Addresss">
              <label>Address</label>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Address"
              />
            </div>
          </div>
          <div className="AdminInputs">
            <label>Last Name</label>
            <input
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
            />
            <br />
            <label>Email Address</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Address"
            />
            <br />
            <label>Role</label>
            <select onChange={(e) => setSelectedOption(e.target.value)}>
              <option value="">Roles</option>
              <option
                selected={selectedOption === "principal"}
                value={"principal"}
              >
                Principal
              </option>
              <option
                selected={selectedOption === "counselor"}
                value={"counselor"}
              >
                Counselor
              </option>
              <option
                selected={selectedOption === "superintendent"}
                value={"superintendent"}
              >
                Superintendent
              </option>
              <option
                selected={selectedOption === "director of it"}
                value={`director of it`}
              >
                Director of IT
              </option>
              <option
                selected={selectedOption === "chief academic officer (CEO)"}
                value={`chief academic officer (CEO)`}
              >
                Chief Academic Officer (CAO)
              </option>
              <option
                selected={
                  selectedOption === "executive assistant/general secretary"
                }
                value={"executive assistant/general secretary"}
              >
                Executive Assistant
              </option>
              <option
                selected={selectedOption === "director of special education"}
                value={"director of special education"}
              >
                Director of Special Education
              </option>
              <option
                selected={selectedOption === "director of bilingual/ELL"}
                value={"director of bilingual/ELL"}
              >
                Director of Bilingual/ELL
              </option>
            </select>
            <br />
            <div className="CreateButton">
              <button className="CreateButton1" onClick={handleClickS}>
                Close
              </button>
              <button onClick={handleSubmit} className="CreateButton2">
                {editId ? "Edit" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Adminnnnnno">
        <div className="AdminDocu">
          <div className="AdminDocTop">
            <h3>Give Permissions</h3>
            <p>
              Enabling Modules for Admin provides basic Read level of access.
              With the permissions setting below, Sub-Admin users can have
              additional write and delete access.
            </p>
          </div>
          <div className="AdminBody">
            <div className="AdminCheckbOx">
              <input
                checked={allPermissions}
                onChange={() => {
                  setAllPermissions(!allPermissions);
                  setOnlyOne(!onlyOne);
                }}
                type="checkbox"
              />
              <label>All Permission</label>
              <input
                checked={onlyOne}
                onChange={() => {
                  setOnlyOne(!onlyOne);
                  setAllPermissions(!allPermissions);
                }}
                type="checkbox"
              />
              <label>Only Selected Role Permission</label>
            </div>
            <div className="AdminAccordion">
              <Accordion>
                {(selectedOption === "principal" || allPermissions) && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Principal Permissions</Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>Discipline:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Access to discipline records and incident reports for
                          all students in a specific school site.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="discipline-switch"
                            checked={discipline}
                            onChange={() => setDiscipline(!discipline)}
                          />
                        </Form>
                      </div>
                      <h3>Scheduling:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Ability to adjust class schedules and teacher
                          assignments.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="scheduling-switch"
                            checked={scheduling}
                            onChange={() => setScheduling(!scheduling)}
                          />
                        </Form>
                      </div>
                      <h3>Data Analysis:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Generate comprehensive reports on school-wide
                          attendance, grades, and performance metrics.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="dataAnalysis-switch"
                            checked={dataAnalysis}
                            onChange={() => setDataAnalysis(!dataAnalysis)}
                          />
                        </Form>
                      </div>
                      <h3>Security:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Oversight of user access and permissions within
                          MyCentrality for a specific school site.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="security-switch"
                            checked={security}
                            onChange={() => setSecurity(!security)}
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "counselor" || allPermissions) && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Guidance Counselor Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>Counseling Records:</h3>
                      <div className="AdminSwicth">
                        <p>Access to student counseling records and notes.</p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="counselingRecords-switch"
                            checked={counselingRecords}
                            onChange={() =>
                              setCounselingRecords(!counselingRecords)
                            }
                          />
                        </Form>
                      </div>
                      <h3>Academic Planning:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Assist in academic scheduling and course selection for
                          students within a specific school.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="academicPlanning-switch"
                            checked={academicPlanning}
                            onChange={() =>
                              setAcademicPlanning(!academicPlanning)
                            }
                          />
                        </Form>
                      </div>
                      <h3>Student Support:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Collaborate with teachers and parents on student
                          academic and behavioral issues.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="studentSupport-switch"
                            checked={studentSupport}
                            onChange={() => setStudentSupport(!studentSupport)}
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "superintendent" || allPermissions) && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Superintendents Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>District-Wide Data:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Access to aggregated data and reports across all
                          schools within a school district.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="districtWideData-switch"
                            checked={districtWideData}
                            onChange={() =>
                              setDistrictWideData(!districtWideData)
                            }
                          />
                        </Form>
                      </div>
                      <h3>Policy Oversight:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Review and approve policies related to student data
                          and academic standards.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="policyOversight-switch"
                            checked={policyOversight}
                            onChange={() =>
                              setPolicyOversight(!policyOversight)
                            }
                          />
                        </Form>
                      </div>

                      <h3>User Management:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Manage user accounts, permissions, and roles within
                          MyCentrality to a superadmin permission.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="userManagement-switch"
                            checked={userManagement}
                            onChange={() => setUserManagement(!userManagement)}
                          />
                        </Form>
                      </div>
                      <h3>Strategic Planning:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Use data analytics to inform district-wide educational
                          strategies and initiatives.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="strategicPlanning-switch"
                            checked={strategicPlanning}
                            onChange={() =>
                              setStrategicPlanning(!strategicPlanning)
                            }
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "director of it" || allPermissions) && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Director of IT Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>User Management:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Manage user accounts, permissions, and roles within
                          MyCentrality.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="userManagement-switch"
                            checked={userManagement}
                            onChange={() => setUserManagement(!userManagement)}
                          />
                        </Form>
                      </div>
                      <h3>Integration:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Oversee integration with other systems and platforms
                          used within the district.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="integration-switch"
                            checked={integration}
                            onChange={() => setIntegration(!integration)}
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "chief academic officer (CEO)" ||
                  allPermissions) && (
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Chief Academic Officers (CAO) Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>Curriculum Management:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Manage and update course catalogs and curriculum
                          standards.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="curriculumManagement-switch"
                            checked={curriculumManagement}
                            onChange={() =>
                              setCurriculumManagement(!curriculumManagement)
                            }
                          />
                        </Form>
                      </div>
                      <h3>Assessment:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Review assessment data and performance metrics to
                          evaluate academic programs.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="assessment-switch"
                            checked={assessment}
                            onChange={() => setAssessment(!assessment)}
                          />
                        </Form>
                      </div>
                      <h3>Professional Development:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Monitor and track professional development activities
                          for teachers and staff.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="professionalDevelopment-switch"
                            checked={professionalDevelopment}
                            onChange={() =>
                              setProfessionalDevelopment(
                                !professionalDevelopment
                              )
                            }
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "executive assistant/general secretary" ||
                  allPermissions) && (
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Executive Assistants, General Secretary Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>Communications:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Manage communications and notifications from school
                          administrators to staff, students, and parents.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="communication-switch"
                            checked={communication}
                            onChange={() => setCommunication(!communication)}
                          />
                        </Form>
                      </div>
                      <h3>Scheduling:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Coordinate meetings and appointments for school
                          administrators using MyCentrality.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="scheduling-switch"
                            checked={scheduling}
                            onChange={() => setScheduling(!scheduling)}
                          />
                        </Form>
                      </div>
                      <h3>Data Access:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Limited access to student and staff data for
                          administrative reporting purposes.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="dataAccess-switch"
                            checked={dataAccess}
                            onChange={() => setDataAccess(!dataAccess)}
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "director of special education" ||
                  allPermissions) && (
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Directors of Special Education Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>IEP Management:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Access and manage Individualized Education Plans
                          (IEPs) for students with special needs.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="IEPmanagement-switch"
                            checked={IEPmanagement}
                            onChange={() => setIEPManagement(!IEPmanagement)}
                          />
                        </Form>
                      </div>
                      <h3>Compliance:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Ensure compliance with special education regulations
                          and reporting requirements.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="compliance-switch"
                            checked={compliance}
                            onChange={() => setCompliance(!compliance)}
                          />
                        </Form>
                      </div>
                      <h3>Data Collection:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Gather and analyze data related to special education
                          programs and student progress.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="dataCollection-switch"
                            checked={dataCollection}
                            onChange={() => setDataCollection(!dataCollection)}
                          />
                        </Form>
                      </div>
                      <h3>Collaboration:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Coordinate with teachers, parents, and external
                          service providers involved in special education
                          services.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="collaboration-switch"
                            checked={collaboration}
                            onChange={() => setCollaboration(!collaboration)}
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
                {(selectedOption === "director of bilingual/ELL" ||
                  allPermissions) && (
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      Director of Bilingual/ELL: Permissions
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="SchoolATabSearch1">
                        <IoSearchOutline />
                        <input placeholder="Search" />
                      </div>
                      <h3>Language Proficiency:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Access to language proficiency assessment data and
                          student support plans.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="languageProficiency-switch"
                            checked={languageProficiency}
                            onChange={() =>
                              setLanguageProficiency(!languageProficiency)
                            }
                          />
                        </Form>
                      </div>
                      <h3>ELL Program Management:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Manage educational programs and services for English
                          Language Learners (ELL).
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="ELLProgrammeManagement-switch"
                            checked={ELLProgrammeManagement}
                            onChange={() =>
                              setELLProgrammeManagement(!ELLProgrammeManagement)
                            }
                          />
                        </Form>
                      </div>
                      <h3>Reporting:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Generate reports on ELL student progress and program
                          effectiveness.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="reporting-switch"
                            checked={reporting}
                            onChange={() => setReporting(!reporting)}
                          />
                        </Form>
                      </div>
                      <h3>Professional Development:</h3>
                      <div className="AdminSwicth">
                        <p>
                          Provide training and support for teachers and staff
                          involved in ELL programs.
                        </p>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="professionalDevelopment-switch"
                            checked={professionalDevelopment}
                            onChange={() =>
                              setProfessionalDevelopment(
                                !professionalDevelopment
                              )
                            }
                          />
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
              <div className="CreateButton">
                <button className="CreateButton1" onClick={resetForm}>
                  Reset
                </button>
                <button
                  className="CreateButton2"
                  onClick={handleSaveAdminPermissions}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
