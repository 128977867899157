import React, { useEffect, useState } from "react";
import "./TeacherCard.css";
import Dummy from "../Images/dummy-user.png";
import { baseUrl } from "../../constants";
const TeacherCard = ({ data = {}, teacher = {}, status = "" }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [adminRole, setAdminRole] = useState("");
  useEffect(() => {
    if (status === "admin") {
      setTitle(data?.name);
      console.log(data);
      setAdminRole(data?.role);
    } else if (status === "districtAdmin") {
      setTitle(teacher?.firstName + " " + teacher?.lastName);
      setDescription(teacher?.email);
    } else if (status === "parent" || status === "student") {
      setTitle(
        `${data?.firstName} ${data?.middleName || ""} ${data?.lastName}`
      );
      setDescription(data?.email);
    } else if (status === "teacher") {
      setTitle(data?.staffName);
      setDescription(data?.profile);
    }
    // eslint-disable-next-line
  }, [status, data]);

  return (
    <div className="TeacherCardMain">
      <div className="TeacherCard">
        <img
          src={`${baseUrl}/images/${data?.profilePicture}`}
          alt="#"
          onError={({ currentTarget }) => {
            currentTarget.src = Dummy;
          }}
        />
        <h3>{title}</h3>
        {adminRole && <p>{adminRole}</p>}
        <p>{description}</p>
      </div>
    </div>
  );
};

export default TeacherCard;
