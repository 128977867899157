import React from "react";
import "./Credential.css";
import { IoCaretForwardOutline, IoHomeSharp } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useUpdateAdminMutation } from "../../redux/APIs";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
const Credential = () => {
  const navigate = useNavigate();
  const { adminId } = useParams();
  console.log(adminId);
  function handlenavigate() {
    navigate("/AdminHome");
  }
  function handleClickS() {
    navigate(-1);
  }

  const { user } = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [logoutAllSessions, setLogoutAllSessions] = useState(false);
  const [sendNotificationEmail, setSendNotificationEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const [updateAdmin] = useUpdateAdminMutation();
  const handleUpdateAdmin = async () => {
    try {
      const res = await updateAdmin({
        firstName,
        newPassword,
        logoutAllSessions,
        sendNotificationEmail,
        changePassword,
        email: user?.email,
        adminId,
      });
      if (res?.error)
        return toast.error(res?.error?.data?.error || "Failed to update admin");
      toast.success("Updated Successfully");
    } catch (error) {}
  };

  return (
    <div>
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handlenavigate}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>Manage Admins</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>Edit Admin Credential</h2>
        </div>
      </div>
      <div className="SchoolMTabs">
        <div className="EditCredentialForm">
          <div className="EditForm">
            <label>First Name*</label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="EditForm1">
            <input
              checked={changePassword}
              onChange={(e) => setChangePassword(!changePassword)}
              type="checkbox"
            />
            <label>Change Password</label>
          </div>
          <div className="EditForm2">
            <label>New password*</label>
            <input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="EditForm2">
            <label>Confirm Password*</label>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="EditForm1">
            <input
              checked={logoutAllSessions}
              onChange={() => setLogoutAllSessions(!logoutAllSessions)}
              type="checkbox"
            />
            <label>
              Logout from all active login sessions (Except the Current Login
              Session in this Device)
            </label>
          </div>
          <div className="EditForm1">
            <input
              checked={sendNotificationEmail}
              onChange={() => setSendNotificationEmail(!sendNotificationEmail)}
              type="checkbox"
            />
            <label>Send Update notification email to admin</label>
          </div>
          <div className="CreateButton">
            <button className="CreateButton1" onClick={handleClickS}>
              Close
            </button>
            <button onClick={handleUpdateAdmin} className="CreateButton2">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credential;
