import React from "react";
import "./AddTeacher.css";
import { useState } from "react";
import {
  IoArrowBackSharp,
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearchOutline,
} from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetSchoolQuery } from "../../redux/APIs";
import { useUpdateTeachersPermissionsMutation } from "../../redux/APIs";
// import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const AddTeacher = () => {
  const navigate = useNavigate();

  const params = useParams();
  function handleClick() {
    navigate(`/SchoolTabs/${params.schoolId}`);
  }

  function handlenavigate() {
    navigate("/AdminHome");
  }

  const { data } = useGetSchoolQuery(
    { schoolId: params.schoolId },
    { skip: !params.schoolId }
  );

  // const [createManageSchedules, setCreateManageSchedules] = useState(true);
  // const [scheduleNotifications, setScheduleNotifications] = useState(true);
  // const [rescheduleClasses, setRescheduleClasses] = useState(true);
  // const [createNewCourses, setCreateNewCourses] = useState(true);
  // const [editExistingCourses, setEditExistingCourses] = useState(true);
  // const [archiveDeleteCourses, setArchiveDeleteCourses] = useState(true);
  // const [accessToDetailedAnalytics, setAccessToDetailedAnalytics] =
  //   useState(true);
  const [uploadCourseMaterials, setUploadCourseMaterials] = useState(true);
  // const [updateOrganizeContent, setUpdateOrganizeContent] = useState(true);
  const [createEditAssignments, setCreateEditAssignments] = useState(true);
  // const [embedMultimedia, setEmbedMultimedia] = useState(true);
  // const [linkExternalResources, setLinkExternalResources] = useState(true);
  // const [enrollRemoveStudent, setEnrollRemoveStudent] = useState(true);
  const [monitorAttendance, setMonitorAttendance] = useState(true);
  const [studentInformation, setStudentInformation] = useState(true);
  const [gradeAssignments, setGradeAssignments] = useState(true);
  // const [provideFeedback, setProvideFeedback] = useState(true);
  // const [manageGradebook, setManageGradebook] = useState(true);
  // const [createQuestionBanks, setCreateQuestionBanks] = useState(true);
  // const [automaticGrading, setAutomaticGrading] = useState(true);
  // const [sendAnnouncements, setSendAnnouncements] = useState(true);
  // const [facilitateDiscussions, setFacilitateDiscussions] = useState(true);
  // const [privateMessaging, setPrivateMessaging] = useState(true);
  // const [viewStudentPerformanceData, setViewStudentPerformanceData] =
  //   useState(true);
  // const [generateReports, setGenerateReports] = useState(true);
  // const [createManageGroups, setCreateManageGroups] = useState(true);
  // const [monitorGroupActivities, setMonitorGroupActivities] = useState(true);
  // const [generateStudentProgressReports, setGenerateStudentProgressReports] =
  //   useState(true);
  // const [classPerformanceAnalytics, setClassPerformanceAnalytics] =
  //   useState(true);
  // const [attendanceReports, setAttendanceReports] = useState(true);
  // const [assessmentAnalytics, setAssessmentAnalytics] = useState(true);
  // const [exportReports, setExportReports] = useState(true);
  // const [generateComplianceReports, setGenerateComplianceReports] =
  //   useState(true);
  // const [createSurveys, setCreateSurveys] = useState(true);
  // const [analyzeSurveyResults, setAnalyzeSurveyResults] = useState(true);

  const [updatePermissionsHandlers] = useUpdateTeachersPermissionsMutation();

  // const { user } = useSelector((state) => state.auth);
  const updatePermissions = async () => {
    try {
      const res = await updatePermissionsHandlers({
        // createManageSchedules,
        // scheduleNotifications,
        // rescheduleClasses,
        // createNewCourses,
        // editExistingCourses,
        // archiveDeleteCourses,
        // accessToDetailedAnalytics,
        uploadCourseMaterials,
        // updateOrganizeContent,
        createEditAssignments,
        // embedMultimedia,
        // linkExternalResources,
        // enrollRemoveStudent,
        monitorAttendance,
        studentInformation,
        gradeAssignments,
        // provideFeedback,
        // manageGradebook,
        // createQuestionBanks,
        // automaticGrading,
        // sendAnnouncements,
        // facilitateDiscussions,
        // privateMessaging,
        // viewStudentPerformanceData,
        // generateReports,
        // createManageGroups,
        // monitorGroupActivities,
        // generateStudentProgressReports,
        // classPerformanceAnalytics,
        // attendanceReports,
        // assessmentAnalytics,
        // exportReports,
        // generateComplianceReports,
        // createSurveys,
        // analyzeSurveyResults,
        schoolId: params?.schoolId,
      });
      if (res.error)
        return toast.error(res?.error?.data?.message || "Something went wrong");

      toast.success("Permissions updated successfully");
    } catch (error) {}
  };

  return (
    <div>
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handlenavigate}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>School</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>{data?.school?.name}</h2>
        </div>
      </div>
      <div className="SchoolMTabs">
        <div className="AddTeacherBody">
          <div className="AddTeacherTop">
            <IoArrowBackSharp onClick={handleClick} />
            <h3>Teacher Permissions</h3>
          </div>
          <div className="TeacherPermission">
            <div className="SchoolATabSearch1">
              <IoSearchOutline />
              <input placeholder="Search" />
            </div>
            {/* <h4>Schedules:</h4> */}
            {/* <div className="TeacherFlex">
              <p>
                Create & Manage Schedules: Teachers can create and manage class
                schedules, including setting up regular class times, office
                hours, and special events.
              </p>
              <Form>
                <Form.Check
                  checked={createManageSchedules}
                  onChange={() =>
                    setCreateManageSchedules(!createManageSchedules)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            {/* <div className="TeacherFlex">
              <p>
                Schedule Notifications: Teachers can set up automatic
                notifications to remind students of upcoming classes, deadlines,
                and events.
              </p>
              <Form>
                <Form.Check
                  checked={scheduleNotifications}
                  onChange={() =>
                    setScheduleNotifications(!scheduleNotifications)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Reschedule Classes: Teachers have the ability to reschedule
                classes or events as needed, and notify students of the changes.
              </p>
              <Form>
                <Form.Check
                  checked={rescheduleClasses}
                  onChange={() => setRescheduleClasses(!rescheduleClasses)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr /> */}
            {/* ./............. */}
            {/* <h4>Course Creation and management:</h4>
            <div className="TeacherFlex">
              <p>
                Create New Courses:Teachers can create new courses from the
                pre-approved list of courses.
              </p>
              <Form>
                <Form.Check
                  checked={createNewCourses}
                  onChange={() => setCreateNewCourses(!createNewCourses)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Edit Existing Courses: Teachers can modify course details,
                syllabus, and structure.
              </p>
              <Form>
                <Form.Check
                  checked={editExistingCourses}
                  onChange={() => setEditExistingCourses(!editExistingCourses)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Archive/Delete Courses: Teachers can archive or delete courses
                when they are no longer needed.
              </p>
              <Form>
                <Form.Check
                  checked={archiveDeleteCourses}
                  onChange={() =>
                    setArchiveDeleteCourses(!archiveDeleteCourses)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Access to Detailed Analytics: Teachers can view in-depth
                analytics about course engagement, student activity, and
                performance trends.
              </p>
              <Form>
                <Form.Check
                  checked={accessToDetailedAnalytics}
                  onChange={() =>
                    setAccessToDetailedAnalytics(!accessToDetailedAnalytics)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr /> */}
            {/* ........... */}

            <h4>Content Integration management:</h4>
            <div className="TeacherFlex">
              <p>
                Upload Course Materials: Teachers can upload lectures, readings,
                videos, and other instructional materials.
              </p>
              <Form>
                <Form.Check
                  checked={uploadCourseMaterials}
                  onChange={() =>
                    setUploadCourseMaterials(!uploadCourseMaterials)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Update and Organize Content: Teachers can edit existing
                materials, organize content into modules or units, and reorder
                materials as needed.
              </p>
              <Form>
                <Form.Check
                  checked={updateOrganizeContent}
                  onChange={() =>
                    setUpdateOrganizeContent(!updateOrganizeContent)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            <div className="TeacherFlex">
              <p>
                Create and Edit Assignments: Teachers can create, update, and
                manage assignments and assessments, including setting due dates
                and grading criteria.
              </p>
              <Form>
                <Form.Check
                  checked={createEditAssignments}
                  onChange={() =>
                    setCreateEditAssignments(!createEditAssignments)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Embed Multimedia: Teachers can embed multimedia content such as
                videos, audio files, and interactive simulations directly into
                course materials.
              </p>
              <Form>
                <Form.Check
                  checked={embedMultimedia}
                  onChange={() => setEmbedMultimedia(!embedMultimedia)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>

            <div className="TeacherFlex">
              <p>
                Link External Resources: Teachers can link to external resources
                and websites to supplement course content.
              </p>
              <Form>
                <Form.Check
                  checked={linkExternalResources}
                  onChange={() =>
                    setLinkExternalResources(!linkExternalResources)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            <hr />
            {/* ................... */}
            <h4>Student management:</h4>
            {/* <div className="TeacherFlex">
              <p>
                Enroll and Remove Students: Teachers can request to add or
                remove students from the course roster.
              </p>
              <Form>
                <Form.Check
                  checked={enrollRemoveStudent}
                  onChange={() => setEnrollRemoveStudent(!enrollRemoveStudent)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            <div className="TeacherFlex">
              <p>
                Monitor Attendance: View and mark attendance for their assigned
                classes and Teachers can track and record student attendance
              </p>
              <Form>
                <Form.Check
                  checked={monitorAttendance}
                  onChange={() => setMonitorAttendance(!monitorAttendance)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Student Information: View basic student demographic information.
              </p>
              <Form>
                <Form.Check
                  checked={studentInformation}
                  onChange={() => setStudentInformation(!studentInformation)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr />
            {/* ................ */}
            <h4>Assessment and Grading Management:</h4>
            <div className="TeacherFlex">
              <p>
                Grade Assignments: Teachers can review and grade student
                submissions.
              </p>
              <Form>
                <Form.Check
                  checked={gradeAssignments}
                  onChange={() => setGradeAssignments(!gradeAssignments)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Provide Feedback: Teachers can leave feedback on assignments and
                assessments.
              </p>
              <Form>
                <Form.Check
                  checked={provideFeedback}
                  onChange={() => setProvideFeedback(!provideFeedback)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            {/* <div className="TeacherFlex">
              <p>
                Manage Gradebook: Teachers can update and manage the course
                gradebook, including entering and adjusting grades.
              </p>
              <Form>
                <Form.Check
                  checked={manageGradebook}
                  onChange={() => setManageGradebook(!manageGradebook)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            {/* <div className="TeacherFlex">
              <p>
                Create Custom Assessments: Teachers can design and implement
                custom quizzes, tests, and other assessments using various
                question types (e.g., multiple choice, short answer, essay).
              </p>
              <Form>
                <Form.Check
                  checked={createQuestionBanks}
                  onChange={() => setCreateQuestionBanks(!createQuestionBanks)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            {/* <div className="TeacherFlex">
              <p>
                Automated Grading: Teachers can set up automated grading By Ai
                for certain types of assessments.
              </p>
              <Form>
                <Form.Check
                  checked={automaticGrading}
                  onChange={() => setAutomaticGrading(!automaticGrading)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            <hr />
            {/* ................ */}
            {/* <h4>Communications Management:</h4>
            <div className="TeacherFlex">
              <p>
                Send Announcements: Teachers can send announcements to all
                students in the course.
              </p>
              <Form>
                <Form.Check
                  checked={sendAnnouncements}
                  onChange={() => setSendAnnouncements(!sendAnnouncements)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Facilitate Discussions: Teachers can create, moderate, and
                participate in discussion forums or class chats.
              </p>
              <Form>
                <Form.Check
                  checked={facilitateDiscussions}
                  onChange={() =>
                    setFacilitateDiscussions(!facilitateDiscussions)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Private Messaging: Teachers can send private messages to
                individual students within their own class, Parents and
                Counselors.
              </p>
              <Form>
                <Form.Check
                  checked={privateMessaging}
                  onChange={() => setPrivateMessaging(!privateMessaging)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr /> */}
            {/* ................ */}
            {/* <h4>Student Progress Tracking:</h4>
            <div className="TeacherFlex">
              <p>
                View Student Performance Data: Teachers can access data on
                student progress, including grades, assignment submissions, and
                engagement metrics.
              </p>
              <Form>
                <Form.Check
                  checked={viewStudentPerformanceData}
                  onChange={() =>
                    setViewStudentPerformanceData(!viewStudentPerformanceData)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Generate Reports: Teachers can generate reports on individual
                and class performance.
              </p>
              <Form>
                <Form.Check
                  checked={generateReports}
                  onChange={() => setGenerateReports(!generateReports)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr /> */}
            {/* ................ */}
            {/* <h4>Collaboration and Group Work:</h4>
            <div className="TeacherFlex">
              <p>
                Create and Manage Groups: Teachers can set up student groups for
                collaborative projects and assignments.
              </p>
              <Form>
                <Form.Check
                  checked={createManageGroups}
                  onChange={() => setCreateManageGroups(!createManageGroups)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Monitor Group Activities: Teachers can oversee and participate
                in group discussions and activities.
              </p>
              <Form>
                <Form.Check
                  checked={monitorGroupActivities}
                  onChange={() =>
                    setMonitorGroupActivities(!monitorGroupActivities)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>

            <hr /> */}

            {/* ................ */}
            {/* <h4>Compliance and Reports:</h4> */}
            {/* <div className="TeacherFlex">
              <p>
                Generate Student Progress Reports: Teachers can generate reports
                on individual student progress, including grades, attendance,
                and participation.
              </p>
              <Form>
                <Form.Check
                  checked={generateStudentProgressReports}
                  onChange={() =>
                    setGenerateStudentProgressReports(
                      !generateStudentProgressReports
                    )
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}
            {/* <div className="TeacherFlex">
              <p>
                Class Performance Analytics: Teachers can analyse and generate
                reports on overall class performance, identifying which Student
                need improvement.
              </p>
              <Form>
                <Form.Check
                  checked={classPerformanceAnalytics}
                  onChange={() =>
                    setClassPerformanceAnalytics(!classPerformanceAnalytics)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div> */}

            {/* <div className="TeacherFlex">
              <p>
                Attendance Reports: Teachers can generate and view reports on
                student attendance, including patterns of absenteeism.
              </p>
              <Form>
                <Form.Check
                  checked={attendanceReports}
                  onChange={() => setAttendanceReports(!attendanceReports)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Assessment Analytics: Teachers can generate reports on
                assessment outcomes, including item analysis to determine which
                questions were most frequently missed.
              </p>
              <Form>
                <Form.Check
                  checked={assessmentAnalytics}
                  onChange={() => setAssessmentAnalytics(!assessmentAnalytics)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Export Reports: Teachers can export reports in various formats
                (e.g., PDF, Excel) for sharing with administrators, parents, or
                other stakeholders.
              </p>
              <Form>
                <Form.Check
                  checked={exportReports}
                  onChange={() => setExportReports(!exportReports)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Generate Compliance Reports: Teachers can generate reports to
                ensure that the course meets institutional, state, or federal
                education standards and regulations.
              </p>
              <Form>
                <Form.Check
                  checked={generateComplianceReports}
                  onChange={() =>
                    setGenerateComplianceReports(!generateComplianceReports)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr /> */}
            {/* ................ */}

            {/* <h4>Feedback and Serveys:</h4>
            <div className="TeacherFlex">
              <p>
                Create Surveys: Teachers can create and distribute surveys to
                gather feedback from students about the course and their
                learning experience.
              </p>
              <Form>
                <Form.Check
                  checked={createSurveys}
                  onChange={() => setCreateSurveys(!createSurveys)}
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Analyze Survey Results: Teachers can view and analyze survey
                results to make informed decisions about course improvements.
              </p>
              <Form>
                <Form.Check
                  checked={analyzeSurveyResults}
                  onChange={() =>
                    setAnalyzeSurveyResults(!analyzeSurveyResults)
                  }
                  type="switch"
                  id="custom-switch"
                />
              </Form>
            </div>
            <hr /> */}

            {/* ................ */}
            <div className="CreateButton">
              <button className="CreateButton1">Reset</button>
              <button onClick={updatePermissions} className="CreateButton2">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeacher;
