import React, { useEffect, useState } from "react";
import ByDate from "../ByDate/ByDate";
import FilterModal from "../Filter/FilterModal";
import { PiDotsNine, PiKeyFill } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TeacherCard from "../TeacherCards/TeacherCard";
import { FaLocationDot } from "react-icons/fa6";
import { TbLockFilled } from "react-icons/tb";
import { RiDragMove2Fill } from "react-icons/ri";
import { FaPlus, FaUserEdit, FaUserLock } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";
import "./DistrictAdmin.css";
import styled from "styled-components";
import Xicon from "../Images/Xicon.png";
import {
  IoCaretBack,
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearchOutline,
} from "react-icons/io5";
import Dummy from "../Images/dummy-user.png";
import { baseUrl } from "../../constants";
import toast from "react-hot-toast";
import {
  useDeleteDistrictAdministratorsMutation,
  useGetAdminsByDistrictIdQuery,
  useLockNewDistrictAdminMutation,
} from "../../redux/APIs";
import { GoPlus } from "react-icons/go";
import { IoIosPeople } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DistrictAdmin = () => {
  const itemsPerPage = 10;
  const [adminsData, setAdminsData] = useState([]);
  const { schoolId } = useParams();
  console.log(schoolId);
  const [adminCurrentPage, setAdminCurrentPage] = useState(0);
  const adminPageCount = Math.ceil(adminsData.length / itemsPerPage);
  const adminOffset = adminCurrentPage * itemsPerPage;
  const currentAdminPageData = adminsData.slice(
    adminOffset,
    adminOffset + itemsPerPage
  );
  const [editId, setEditId] = useState("");

  const { user } = useSelector((state) => state.auth);
  const [showTable, setShowTable] = useState(true);
  const handleAdminsPageClick = ({ selected }) => {
    setAdminCurrentPage(selected);
  };
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEditId("");
    setOpen(false);
  };
  const [deleteAdminHandler] = useDeleteDistrictAdministratorsMutation();
  const { data: apiAdmins } = useGetAdminsByDistrictIdQuery(
    {
      districtId: user?.districtId,
    },
    {
      skip: !user?.districtId,
    }
  );

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (!searchValue && apiAdmins) {
      setAdminsData(apiAdmins?.adminSchoolCounts);
    } else if (searchValue && apiAdmins) {
      const filteredData = apiAdmins?.adminSchoolCounts.filter(
        (item) =>
          item?.firstName?.toLowerCase().includes(searchValue.toLowerCase()) ||
          item?.lastName?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setAdminsData(filteredData);
    } else {
      setAdminsData([]);
    }
  }, [apiAdmins, searchValue]);

  const navigate = useNavigate();
  const handleDeleteSchool = async () => {
    const toastId = toast.loading("Deleting Admin...");
    try {
      const res = await deleteAdminHandler(editId);

      if (res?.error)
        return toast.error(
          res?.error?.data?.message || "Failed to delete admin",
          {
            id: toastId,
          }
        );

      toast.success("Admin deleted successfully", {
        id: toastId,
      });

      handleClose();
      setEditId("");
    } catch (error) {
      console.log(error);
    }
  };
  const [checkedItems, setCheckedItems] = useState([]);
  const handleRowCheckboxChange = (id) => {
    if (checkedItems.includes(id)) {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    } else {
      setCheckedItems([...checkedItems, id]);
    }
  };
  const [allChecked, setAllChecked] = useState(false);
  const handleHeaderCheckboxChange = () => {
    if (allChecked) {
      setCheckedItems([]);
    } else {
      setCheckedItems(currentAdminPageData.map((item) => item.id));
    }
    setAllChecked(!allChecked);
  };

  // const [isShow, setIsShow] = useState(false);

  const SwitchContainer = styled.div`
    display: inline-block;
    width: 50px;
    height: 23px;
    position: relative;
  `;

  const SwitchInput = styled.input`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  `;

  const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.checked ? props.onColor : props.offColor};
    transition: 0.4s;
    border-radius: 34px;
    pointer-events: none;
    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
      transform: ${(props) =>
        props.checked ? "translateX(26px)" : "translateX(0)"};
    }
  `;

  const Switch = ({
    isChecked,
    onToggle,
    onColor = "#E34444",
    offColor = "#00AFEF",
  }) => {
    return (
      <SwitchContainer onClick={onToggle}>
        <SwitchInput type="checkbox" checked={isChecked} readOnly />
        <Slider
          checked={isChecked}
          onColor={onColor}
          offColor={offColor}
        ></Slider>
      </SwitchContainer>
    );
  };
  function handleClick() {
    navigate("/AdminHome");
  }

  const [handleLock] = useLockNewDistrictAdminMutation();

  const handleLockSchool = async (islock, adminId) => {
    const res = await handleLock({ islock, adminId });
    console.log(res, "I'm res");
    if (res.error)
      return toast.error(`Failed to ${!islock ? "unlock" : "lock"} admin`);
    toast.success(`Admin ${islock ? "locked" : "unlocked"}`);
    try {
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="DistrictAdminsMain">
      <div className="SchoolAdminTab">
        <div className="SchoolTop">
          <div className="SchoolHome">
            <div className="SchoolHomeIcon" onClick={handleClick}>
              <IoHomeSharp />
              <span>Home</span>
            </div>
            <IoCaretForwardOutline />
            <div className="SchoolHomeIcon">
              <IoIosPeople />
              <span>Total District Administrators</span>
            </div>
          </div>
          <hr />
          <div className="SchoolName">
            <h2>Total District Administrators</h2>
          </div>
        </div>
        <div className="DistrictPAdd">
          <div className="SchoolATabTop">
            <div className="SchoolFilter">
              <div className="SchoolATabSearch">
                <IoSearchOutline />
                <input
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search"
                />
              </div>
              <ByDate />
              <FilterModal />
              <div
                className="SchoolToggle"
                onClick={() => {
                  setShowTable(!showTable);
                }}
              >
                <span style={{ backgroundColor: !showTable && "#00afef" }}>
                  <PiDotsNine
                    style={{ color: !showTable ? "white" : "#1E293B" }}
                  />
                </span>
                <span style={{ backgroundColor: showTable && "#00afef" }}>
                  <RxHamburgerMenu
                    style={{ color: showTable ? "white" : "#1E293B" }}
                  />
                </span>
              </div>
            </div>
            <div className="AddAdmintratorADMin">
              <button className="LastBtn">
                <img src={Xicon} alt="xIcon" />
                Export Data
              </button>
              <button className="fdsgfgbutton">
                <Link to="/ImportData?bulk=true">
                  <FaPlus />
                  Import Data
                </Link>
              </button>
              <Link to="/AddDistrictAdmin" className="AddnewAdmin">
                <GoPlus />
                Add New District Admin
              </Link>
            </div>
          </div>

          {showTable && (
            <div>
              <div className="SchoolTable">
                <table>
                  <thead>
                    <tr>
                      <th>S#</th>
                      <th>
                        <input
                          type="checkbox"
                          checked={allChecked}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>District ID</th>
                      <th>Total Schools</th>
                      <th>Email</th>
                      <th>Unlock/Lock</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentAdminPageData.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1 + adminOffset}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={checkedItems.includes(item.id)}
                            onChange={() => handleRowCheckboxChange(item.id)}
                          />
                        </td>
                        <td>
                          <div className="SAdminProfile">
                            <img
                              src={`${baseUrl}/images/${item?.profilePicture}`}
                              onError={({ currentTarget }) => {
                                currentTarget.src = Dummy;
                              }}
                              alt="Profile"
                            />
                            <p>{item?.firstName}</p>
                          </div>
                        </td>
                        <td>
                          <div className="SAdminProfile">
                            <p>{item?.lastName}</p>
                          </div>
                        </td>
                        <td>
                          <div className="SAdminAddress">
                            <FaLocationDot />
                            <p>{user?.districtId}</p>
                          </div>
                        </td>
                        <td>{item?.uniqueSchoolCount}</td>
                        <td>{item?.email}</td>
                        <td>
                          <div
                            onClick={() =>
                              handleLockSchool(!item?.islock, item?.id)
                            }
                          >
                            <Switch
                              isChecked={item?.islock}
                              onColor="#E34444"
                              offColor="#00AFEF"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="SIcons">
                            <TbLockFilled />
                            <RiDragMove2Fill />
                            <Link to={`/EditCredential/_/${item?.id}`}>
                              <FaUserLock />
                            </Link>
                            <PiKeyFill />
                            <FaUserEdit
                              onClick={() =>
                                navigate(`/AddDistrictAdmin?editId=${item?.id}`)
                              }
                            />
                            <MdDelete
                              onClick={() => {
                                setEditId(item?.id);
                                handleOpen();
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <h3 className="ModalHeadd">
                          Are you want to delete this admin?
                        </h3>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="ModalBTNS">
                          <button className="ModalBtn1" onClick={handleClose}>
                            No
                          </button>
                          <button
                            className="ModalBtn2"
                            onClick={handleDeleteSchool}
                          >
                            Yes
                          </button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
              <div>
                <ReactPaginate
                  previousLabel={<IoCaretBack />}
                  nextLabel={<IoCaretForwardOutline />}
                  breakLabel={"..."}
                  pageCount={adminPageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handleAdminsPageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          )}
          {!showTable && (
            <div>
              <div className="TeacherCArdInSch">
                {currentAdminPageData.map((teacher) => (
                  <TeacherCard
                    status={"districtAdmin"}
                    key={teacher.id}
                    teacher={teacher}
                    data={teacher}
                  />
                ))}
              </div>
              <ReactPaginate
                previousLabel={<IoCaretBack />}
                nextLabel={<IoCaretForwardOutline />}
                breakLabel={"..."}
                pageCount={adminPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handleAdminsPageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DistrictAdmin;
