import React from "react";
import "./Pagination";
import Xicon from "../Images/Xicon.png";
import { CiSearch } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import ByDate from "../ByDate/ByDate";
import FilterModal from "../Filter/FilterModal";
import Keyboard from "../KeyboardModal/Keyboard";

const Pagination = ({ searchValue = "", setSearchValue = () => {} }) => {
  return (
    <div className="maiin">
      <div className="schoolTop">
        <div className="first">
          <h2>All Schools</h2>
          <div className="input">
            <CiSearch />
            <input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              type="text"
              placeholder="Search"
            />
          </div>
          <ByDate />
          <FilterModal />
        </div>
        <div className="last">
          <button className="LastBtn">
            <img src={Xicon} alt="xIcon" />
            Export Data
          </button>
          <button className="button">
            <Link to="/ImportData">
              <FaPlus />
              Import Data
            </Link>
          </button>

          <Keyboard />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
