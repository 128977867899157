import React from "react";
import "./Importdata.css";
import DownloadExcelImage from "../Images/Group 1000006269.png";
import { IoIosPlay } from "react-icons/io";
import MapExcelIcon from "../Images/map-excel.png";
import MapPreviewIcon from "../Images/map-preview.png";
import CloudComputingIcon from "../Images/cloud-computing.png";
import ImportToDatabaseIcon from "../Images/import-to-database.png";
import UploadFileIcon from "../Images/UpGroup.png";
import { useRef, useState } from "react";
import {
  useUploadBulkCSVMutation,
  useUploadSchoolsCSVMutation,
} from "../../redux/APIs";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

const Importdata = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const imageRef = useRef(null);
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleImageSelection = () => {
    imageRef.current.click();
  };

  const bulk = useSearchParams()[0].get("bulk");

  const [uploadCSVHandler] = useUploadSchoolsCSVMutation();
  const [uploadBulkCSV] = useUploadBulkCSVMutation();

  const [loading, setLoading] = useState(false);

  const handleUploadCSV = async () => {
    setLoading(true);
    const toastId = toast.loading("Uploading CSV...");
    try {
      const newForm = new FormData();
      newForm.append("file", selectedFile);
      if (bulk) {
        const res = await uploadBulkCSV(newForm);

        if (res?.error) {
          setLoading(false);
          return toast.error(
            res?.error?.data?.message || "Something went wrong",
            {
              id: toastId,
            }
          );
        }
        toast.success("CSV Uploaded Successfully", {
          id: toastId,
        });

        setLoading(false);
      } else {
        const res = await uploadCSVHandler(newForm);

        if (res?.error) {
          setLoading(false);
          return toast.error(
            res?.error?.data?.message || "Something went wrong",
            {
              id: toastId,
            }
          );
        }
        toast.success("CSV Uploaded Successfully", {
          id: toastId,
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="import-data-container">
      <div className="import-data-upper-div">
        <p>School</p>
        <IoIosPlay color="#c2c6ce" />
        <p>Import Data</p>
      </div>
      <div className="import-data-upper-section">
        <p className="import-data-step-1">Step 1</p>
        <div className="import-data-first-section-images-div">
          <img
            className="download-excel-image"
            src={DownloadExcelImage}
            alt="#"
          />
        </div>
        <p>
          Click here to generate and download the data import template in Excel.
          Add your data to this Tamplete <br /> and upload the file.{" "}
          <span>Do not rename any column headers.</span>
        </p>
      </div>
      <div className="import-data-upload-cards">
        <div className="import-data-upload-card import-data-first-upload-card">
          <span className="import-data-upload-steps">Step 2</span>
          <img
            src={CloudComputingIcon}
            className="import-data-upload-icons"
            alt="#"
          />

          <p className="upload-file-p">Upload your file</p>
          <p className="upload-file-second-p">Upload xls,xlsx or csv</p>
        </div>
        <div className="import-data-upload-card">
          <span style={{ bottom: 18 }} className="import-data-other-steps">
            Step 3
          </span>
          <img
            src={MapExcelIcon}
            alt="#"
            className="import-data-upload-icons"
          />
          <p className="upload-file-p">Map Your Fields</p>
          <div>
            <p className="upload-file-second-p">Map your spreadsheet</p>
            <p className="upload-file-second-p">
              columns with database fields csv
            </p>
          </div>
        </div>

        <div className="import-data-upload-card">
          <span className="import-data-other-steps">Step 4</span>
          <img
            src={MapPreviewIcon}
            alt="#"
            className="import-data-upload-icons"
          />
          <p className="upload-file-p">Upload your file</p>
          <p className="upload-file-second-p">
            Review your mapping before your proceed to import
          </p>
        </div>
        <div className="import-data-upload-card">
          <span className="import-data-other-steps">Step 5</span>
          <img
            src={ImportToDatabaseIcon}
            alt="#"
            className="import-data-upload-icons"
          />

          <p className="upload-file-p">Upload your file</p>
          <p className="upload-file-second-p">
            Finally, import your data and see results
          </p>
        </div>
      </div>
      <div className="upload-file-section">
        <div
          className="upload-file-section-content"
          onClick={handleImageSelection}
        >
          <img src={UploadFileIcon} alt="#" className="upload-file-image" />
          <div className="upload-file-section-texts">
            <p
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              Upload file from your Local Drive
            </p>
            <p>
              {selectedFile
                ? selectedFile?.name
                : "Click here to select a file or drop the file here"}{" "}
            </p>
            <input
              type="file"
              style={{ display: "none" }}
              ref={imageRef}
              name=""
              accept=".csv"
              onChange={handleFileSelect}
              id=""
            />
          </div>
        </div>
        <div className="upload-file-button-container">
          <button
            onClick={handleUploadCSV}
            disabled={loading}
            className="upload-file-button"
          >
            Upload File
          </button>
        </div>
      </div>
      <p style={{ textAlign: "center", padding: "2rem" }}>
        ©2023 MyCentrality. All Rights Reserved.
      </p>
    </div>
  );
};

export default Importdata;
