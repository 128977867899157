import React, { useEffect, useState } from "react";
import "./Recycle.css";
import {
  RiDeleteBin6Line,
  RiDeleteBinLine,
  RiDragMove2Fill,
} from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import Tab from "react-bootstrap/Tab";
import toast from "react-hot-toast";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useGetDistrictSchoolsTrashbinQuery,
  useDeleteSchoolFromTrashbinMutation,
  useGetDistrictSchoolsAdminsTrashbinQuery,
  useGetDistrictAdministratorsQuery,
  useDeleteDistrictAdministratorsMutation,
  useDeleteDistrictSchoolAdminFromTrashbinMutation,
  useRestoreDistrictAdministratorsMutation,
  useTrashbinLockAdministratorMutation,
  useDestroyRecycleAdministratorsMutation,
  useTrashbinLockSchoolAdminMutation,
  useRestoreSchoolsAdminsFromTrashbinMutation,
  useDestroyDistrictSchoolsTrashbinMutation,
  useDestroySchoolAdminsFromTrashbinMutation,
  useRestoreDistrictSchoolsFromTrashbinMutation,
} from "../../redux/APIs";
import {
  IoCaretBack,
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearch,
} from "react-icons/io5";
import { TbLockFilled } from "react-icons/tb";
import { FaUserEdit, FaUserLock } from "react-icons/fa";
import { PiKeyFill, PiRecycleBold } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Dummy from "../Images/dummy-user.png";
import { baseUrl } from "../../constants";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Recycle = () => {
  const { user } = useSelector((state) => state.auth);

  console.log(user, "Role");
  const { data: districtAdministrator } = useGetDistrictAdministratorsQuery(
    {
      districtId: user?.districtId,
    },
    {
      skip: !user?.districtId,
    }
  );

  const [districtAdministrators, setDistrictAdministrators] = useState([]);

  useEffect(() => {
    if (districtAdministrator) {
      setDistrictAdministrators(districtAdministrator?.data);
    }
  }, [districtAdministrator]);

  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line
  const [isShow, setIsShow] = useState(false);
  console.log(setIsShow);
  const itemsPerPage = 10;

  useEffect(() => {
    if (isShow) {
      setOpen(true);
    }
  }, [isShow]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/AdminHome");
  };

  const SwitchContainer = styled.div`
    display: inline-block;
    width: 50px;
    height: 23px;
    position: relative;
  `;

  const SwitchInput = styled.input`
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  `;
  const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.checked ? props.onColor : props.offColor};
    transition: 0.4s;
    border-radius: 34px;
    pointer-events: none;
    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
      transform: ${(props) =>
        props.checked ? "translateX(26px)" : "translateX(0)"};
    }
  `;
  const Switch = ({
    isChecked,
    onToggle,
    onColor = "#E34444",
    offColor = "#00AFEF",
  }) => {
    return (
      <SwitchContainer onClick={onToggle}>
        <SwitchInput type="checkbox" checked={isChecked} readOnly />
        <Slider
          checked={isChecked}
          onColor={onColor}
          offColor={offColor}
        ></Slider>
      </SwitchContainer>
    );
  };

  const [deleteId, setDeleteId] = useState("");
  // District Schools
  const [districtSchoolsData, setDistrictSchoolsData] = useState([]);
  const [districtSchoolsCurrentPage, setDistrictSchoolsCurrentPage] =
    useState(0);
  const districtSchoolsOffset = districtSchoolsCurrentPage * itemsPerPage;

  const districtSchoolsPageCount = Math.ceil(
    districtSchoolsData.length / itemsPerPage
  );

  const handlePageClickOnDistrictSchools = (event) => {
    const newPage = event.selected;
    setDistrictSchoolsCurrentPage(newPage);
  };
  const districtSchoolsCurrentData = districtSchoolsData.slice(
    districtSchoolsOffset,
    districtSchoolsOffset + itemsPerPage
  );

  const [schoolAdmins, setSchoolAdmins] = useState([]);

  // District Schools

  const [schoolAdminsCurrentPage, setSchoolAdminsCurrentPage] = useState(0);
  const schoolAdminsOffset = schoolAdminsCurrentPage * itemsPerPage;

  const schoolAdminsPageCount = Math.ceil(
    districtAdministrators.length / itemsPerPage
  );

  const handlePageClickOnSchoolAdmins = (event) => {
    const newPage = event.selected;
    setSchoolAdminsCurrentPage(newPage);
  };
  const schoolAdminsCurrentData = schoolAdmins.slice(
    schoolAdminsCurrentPage,
    schoolAdminsOffset + itemsPerPage
  );

  // End
  const [
    districtAdministratorsCurrentPage,
    setDistrictAdministratorsCurrentPage,
  ] = useState(0);
  const districtAdministratorsOffset =
    districtAdministratorsCurrentPage * itemsPerPage;

  const districtAdministratorsPageCount = Math.ceil(
    districtAdministrators.length / itemsPerPage
  );

  const handlePageClickOnDistrictAdministrators = (event) => {
    const newPage = event.selected;
    setDistrictAdministratorsCurrentPage(newPage);
  };
  const districtAdministratorsCurrentData = districtAdministrators.slice(
    districtAdministratorsCurrentPage,
    districtAdministratorsOffset + itemsPerPage
  );

  const [destroyDistrictSchoolsHandler] =
    useDestroyDistrictSchoolsTrashbinMutation();

  const { data: districtAdmins } = useGetDistrictSchoolsAdminsTrashbinQuery(
    { districtId: user?.districtId },
    { skip: !user?.districtId }
  );

  console.log(districtAdmins, "We're dis");
  useEffect(() => {
    if (districtAdmins) {
      console.log(districtAdmins, "We're district admins");
    }
  }, [districtAdmins]);

  const { data: districtSchools } = useGetDistrictSchoolsTrashbinQuery(
    { districtId: user?.districtId },
    { skip: !user?.districtId }
  );

  const [title, setTitle] = useState("");
  const [ids, setIds] = useState([]);

  useEffect(() => {
    if (districtAdmins) {
      setSchoolAdmins(districtAdmins?.schools);
    }
  }, [districtAdmins]);

  const handleIds = (id) => {
    setIds((prev) => {
      return prev.includes(id)
        ? prev.filter((myId) => {
            return myId !== id;
          })
        : [...prev, id];
    });
  };

  const [deleteStatus, setDeleteStatus] = useState("");

  const [showDeleteButtons, setShowDeleteButtons] = useState(false);

  const [isPermanentDelete, setIsPermanentDelete] = useState(false);
  useEffect(() => {
    if (ids && ids.length > 0) {
      setShowDeleteButtons(true);
    } else {
      setShowDeleteButtons(false);
    }
  }, [ids]);

  useEffect(() => {
    if (districtSchools) {
      setDistrictSchoolsData(districtSchools?.data?.schools);
    }
  }, [districtSchools]);

  const [deleteTrashbinSchoolHandler] = useDeleteSchoolFromTrashbinMutation();

  const [deleteDistrictSchoolAdmin] =
    useDeleteDistrictSchoolAdminFromTrashbinMutation();
  const [deleteDistrictAdministrators] =
    useDeleteDistrictAdministratorsMutation();
  const [restoreDistrictSchools] =
    useRestoreDistrictSchoolsFromTrashbinMutation();
  const [destroyStatus, setDestroyStatus] = useState("");
  const handleDeleteTrashbinSchool = async () => {
    const toastId = toast.loading("Deleting...");
    try {
      if (deleteStatus === "administrators") {
        const res = await deleteDistrictAdministrators(deleteId);

        if (res.error)
          return toast.error("Failed to delete administrator", {
            id: toastId,
          });

        setDeleteId("");
        setOpen(false);
        setDeleteStatus("");
        toast.success("Administrator Deleted", {
          id: toastId,
        });
      } else if (deleteStatus === "schoolsAdmin") {
        const res = await deleteDistrictSchoolAdmin({ adminId: deleteId });

        if (res.error)
          return toast.error("Failed to delete admin", {
            id: toastId,
          });

        setDeleteId("");
        setOpen(false);
        setDeleteStatus("");
        toast.success("Admin Deleted", {
          id: toastId,
        });
      } else if (deleteStatus === "districtSchools") {
        const res = await deleteTrashbinSchoolHandler({ schoolId: deleteId });

        if (res.error)
          return toast.error("Failed to delete School", {
            id: toastId,
          });

        setDeleteId("");
        setOpen(false);
        setDeleteStatus("");
        toast.success("School Deleted", {
          id: toastId,
        });
      } else if (restoreStatus === "districtSchools") {
        console.log("Destroyed");
      }
    } catch (error) {}
  };

  const [restoreStatus, setRestoreStatus] = useState("");

  const [restoreAdministrators] = useRestoreDistrictAdministratorsMutation();
  const [destroyAdministrators] = useDestroyRecycleAdministratorsMutation();
  // eslint-disable-next-line
  const [schoolId, setSchoolId] = useState("");
  const [handleRestoreSchoolsAdmin] =
    useRestoreSchoolsAdminsFromTrashbinMutation();
  const [destroySchoolAdmins] = useDestroySchoolAdminsFromTrashbinMutation();
  const submitFunction = async () => {
    try {
      if (restoreStatus === "administrators") {
        const res = await restoreAdministrators({
          adminIds: ids,
        });
        if (res.error) return toast.error("Failed to restore admins");
        toast.success("Admins Restored");
        setIds([]);
        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      } else if (destroyStatus === "administrators") {
        console.log("Got destroyed");
        const res = await destroyAdministrators({
          adminIds: ids,
        });
        console.log(res, "I'm responsed");
        if (res.error) return toast.error("Failed to deleted admins");
        toast.success("Admins Deleted");
        setIds([]);

        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      } else if (destroyStatus === "districtSchools") {
        const res = await destroyDistrictSchoolsHandler(ids);
        console.log(res, "Juu baal");
        if (res.error) return toast.error("Failed to deleted schools");

        setIds([]);

        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      } else if (destroyStatus === "schoolAdmins") {
        const res = await handleRestoreSchoolsAdmin(ids);

        console.log(res, "I'm datas");
        if (res.error) return toast.error("Failed to restore admins");
        toast.success("Admins Restored");

        setIds([]);
        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      } else if (destroyStatus === "districtSchools") {
        const res = await restoreDistrictSchools(ids);

        console.log(res, "I'm dts");
        if (res.error) return toast.error("Failed to restore schools");
        toast.success("Schools Restored");

        setIds([]);
        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      } else if (restoreStatus === "districtSchools") {
        console.log("Destroyed");
        const res = await restoreDistrictSchools(ids);

        if (res.error) return toast.error("Failed to restore schools");

        toast.success("Schools Restored");
        setIds([]);
        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      } else if (destroyStatus === "schoolAdmins") {
        const res = await destroySchoolAdmins(ids);

        if (res.error) return toast.error("Failed to delete admins");

        toast.success("Admins Deleted");
        setIds([]);
        setRestoreStatus("");
        setDestroyStatus("");
        setOpen(false);
      }
    } catch (error) {}
  };

  const [handleLockUnlock] = useTrashbinLockAdministratorMutation();
  const [handleLockUnlockAdmin] = useTrashbinLockSchoolAdminMutation();
  // eslint-disable-next-line
  const handleLockUnlockSchool = async (lockStatus, id) => {
    try {
      const res = await handleLockUnlock({ islock: lockStatus, adminId: id });

      if (res.error) return toast.error("Failed to lock/unlock admin");

      toast.success(`${lockStatus ? "Locked" : "Unlocked"}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLockUnlockSchoolAdmin = async (lockStatus, id) => {
    try {
      console.log("Mainer");
      const res = await handleLockUnlockAdmin({
        islock: lockStatus,
        adminId: id,
      });

      console.log(res, "I'm responsed");
      if (res.error) return toast.error("Failed to lock/unlock admin");

      toast.success(`${lockStatus ? "Locked" : "Unlocked"}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="SchoolDash">
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handleClick}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <RiDeleteBinLine />
            <span>Recycle bin</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>Recycle bin</h2>
        </div>
      </div>
      <Tab.Container defaultActiveKey="first" id="left-tabs-example">
        <div className="SchoolMTabs">
          <Nav variant="pills" className="SchoolNav">
            <Nav.Item>
              <Nav.Link onClick={() => setIds([])} eventKey="first">
                District Administrator
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => setIds([])} eventKey="second">
                District Schools
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => setIds([])} eventKey="third">
                Schools Admins
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="SchoolTAbBody">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="RecycleMain">
                <div className="RecycleTrashMain">
                  <div className="RecycleSearch">
                    <IoSearch />
                    <input placeholder="Search ." />
                  </div>

                  {showDeleteButtons && (
                    <div className="RecycleTrash">
                      <RiDeleteBin6Line
                        onClick={() => {
                          setTitle("Do you want permanent delete this?");
                          setOpen(true);
                          setIsPermanentDelete(true);
                          setDestroyStatus("administrators");
                        }}
                      />
                      |
                      <PiRecycleBold
                        onClick={() => {
                          setTitle("Do you want Restore this?");
                          setOpen(true);
                          setRestoreStatus("administrators");
                          setIsPermanentDelete(false);
                        }}
                      />
                    </div>
                  )}
                </div>
                <h4>Recent delete</h4>
                <div className="SchoolTable">
                  <table>
                    <tbody>
                      {districtAdministratorsCurrentData?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>
                            <input
                              checked={ids.includes(item.id)}
                              type="checkbox"
                              onChange={() => handleIds(item.id)}
                            />
                          </td>
                          <td>
                            <div className="RecycleProfile">
                              <img
                                src={`${baseUrl}/images/${item?.profilePicture}`}
                                alt="profile"
                                onError={({ currentTarget }) => {
                                  currentTarget.src = Dummy;
                                }}
                              />
                              {item?.firstName} {item?.lastName}
                            </div>
                          </td>

                          <td></td>
                          <td>
                            <div className="SAdminAddress">
                              <FaLocationDot />
                              <p>{item?.districtId}</p>
                            </div>
                          </td>
                          <td>{item?.schoolCount}</td>

                          <td>{item?.email}</td>
                          <td>
                            <div>
                              <Switch
                                isChecked={item?.islock}
                                onColor="#B7BAC7"
                                offColor="#B7BAC7"
                              />
                            </div>
                          </td>
                          <td>
                            <div className="SIcons">
                              <TbLockFilled />
                              <RiDragMove2Fill />
                              <FaUserLock />
                              <PiKeyFill />
                              <FaUserEdit />
                              <MdDelete
                                onClick={() => {
                                  setDeleteStatus("administrators");
                                  setDeleteId(item?.id);
                                  setTitle(
                                    "Do you want permanent delete this?"
                                  );
                                  setOpen(true);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <MyModal
                open={open}
                setOpen={setOpen}
                title={title}
                func={submitFunction}
                func2={handleDeleteTrashbinSchool}
                isPermanentDelete={isPermanentDelete}
                deleteId={deleteId}
              />
              <ReactPaginate
                previousLabel={<IoCaretBack />}
                nextLabel={<IoCaretForwardOutline />}
                breakLabel={"..."}
                pageCount={districtAdministratorsPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickOnDistrictAdministrators}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="RecycleMain">
                <div className="RecycleTrashMain">
                  <div className="RecycleSearch">
                    <IoSearch />
                    <input placeholder="Search ." />
                  </div>
                  {showDeleteButtons && (
                    <div className="RecycleTrash">
                      <RiDeleteBin6Line
                        onClick={() => {
                          setTitle("Do you want permanent delete this?");
                          setIsPermanentDelete(true);
                          setDestroyStatus("districtSchools");
                          setOpen(true);
                        }}
                      />
                      |
                      <PiRecycleBold
                        onClick={() => {
                          setTitle("Do you want Restore this?");
                          setIsPermanentDelete(false);
                          setRestoreStatus("districtSchools");
                          setOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>

                <h4>Recent delete</h4>
                <div className="SchoolTable">
                  <table>
                    <tbody>
                      {districtSchoolsCurrentData?.map((school, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <input
                                checked={ids.includes(school?.id)}
                                type="checkbox"
                                onChange={() => handleIds(school?.id)}
                              />
                            </td>
                            <td>{school?.name}</td>
                            <td>
                              <div className="SAdminAddress">
                                <FaLocationDot />
                                <p>
                                  {school?.address1} {school?.address2}
                                </p>
                              </div>
                            </td>

                            <td>{school?.description}</td>
                            <td>{school?.district}</td>
                            <td>{school?.email}</td>
                            <td>{school?.telephone}</td>
                            <td>
                              <Switch
                                isChecked={school?.islocked}
                                onColor="#B7BAC7"
                                offColor="#B7BAC7"
                              />
                            </td>
                            <td>
                              <select>
                                <option value="In progress">In progress</option>
                              </select>
                            </td>
                            <td>
                              <div className="SIcons">
                                <RiDragMove2Fill />
                                <MdDelete
                                  onClick={() => {
                                    setDeleteStatus("districtSchools");
                                    console.log(school?.id, "I'm school id");
                                    setDeleteId(school?.id);
                                    setTitle(
                                      "Do you want permanent delete this?"
                                    );

                                    setSchoolId(school?.id);
                                    setOpen(true);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div>
                  <ReactPaginate
                    previousLabel={<IoCaretBack />}
                    nextLabel={<IoCaretForwardOutline />}
                    breakLabel={"..."}
                    pageCount={districtSchoolsPageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClickOnDistrictSchools}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <div className="RecycleMain">
                <div className="RecycleTrashMain">
                  <div className="RecycleSearch">
                    <IoSearch />
                    <input placeholder="Search ." />
                  </div>
                  {showDeleteButtons && (
                    <div className="RecycleTrash">
                      <RiDeleteBin6Line
                        onClick={() => {
                          setIsPermanentDelete(true);
                          setTitle("Do you want permanent delete this?");
                          setDestroyStatus("schoolAdmins");
                          setOpen(true);
                        }}
                      />
                      |
                      <PiRecycleBold
                        onClick={() => {
                          setTitle("Do you want Restore this?");
                          setDestroyStatus("schoolAdmins");
                          setIsPermanentDelete(false);
                          setOpen(true);
                        }}
                      />
                    </div>
                  )}
                </div>
                <h4>Recent delete</h4>
                <div className="SchoolTable">
                  <table>
                    <tbody>
                      {schoolAdminsCurrentData?.map((school, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <input
                                checked={ids.includes(school?.id)}
                                type="checkbox"
                                onChange={() => handleIds(school?.id)}
                              />
                            </td>
                            <td>
                              <div className="RecycleProfile">
                                <img
                                  onError={({ currentTarget }) => {
                                    currentTarget.src = Dummy;
                                  }}
                                  src={`${baseUrl}/images/${school?.profilePicture}`}
                                  alt="profile"
                                />
                                {school?.firstname}
                              </div>
                            </td>
                            <td>
                              <div className="RecycleProfile">
                                {school?.lestname}
                              </div>
                            </td>
                            <td>
                              <div className="SAdminAddress">
                                <FaLocationDot />

                                <p>{school?.address}</p>
                              </div>
                            </td>
                            <td>{school?.districtid}</td>
                            <td>{school?.email}</td>
                            <td>
                              <div className="AdminRole">
                                <h3>{school?.role}</h3>
                              </div>
                            </td>
                            <td>
                              <div
                                onClick={() => {
                                  console.log("God of it");
                                  handleLockUnlockSchoolAdmin(
                                    !school?.islock,
                                    school?.id
                                  );
                                }}
                              >
                                <Switch
                                  isChecked={school?.islock}
                                  onColor="#B7BAC7"
                                  offColor="#B7BAC7"
                                />
                              </div>
                            </td>
                            <td>
                              <select>
                                <option value="In progress">In progress</option>
                              </select>
                            </td>
                            <td>
                              <div className="SIcons">
                                <RiDragMove2Fill />
                                <MdDelete
                                  onClick={() => {
                                    setDeleteStatus("schoolsAdmin");

                                    setDeleteId(school?.id);
                                    setTitle(
                                      "Do you want permanent delete this?"
                                    );

                                    setOpen(true);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <ReactPaginate
                previousLabel={<IoCaretBack />}
                nextLabel={<IoCaretForwardOutline />}
                breakLabel={"..."}
                pageCount={schoolAdminsPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClickOnSchoolAdmins}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

function MyModal({
  open,
  setOpen,
  title,
  func = () => {},
  func2 = () => {},
  isPermanentDelete = false,
  setDeleteId = () => {},
  deleteId = "",
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setDeleteId("");
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="BinMain">
              <div
                className="BinLogo"
                style={{
                  backgroundColor:
                    isPermanentDelete || deleteId ? "#E34444" : "#23BD33",
                }}
              >
                {isPermanentDelete ? <RiDeleteBin6Line /> : <PiRecycleBold />}
              </div>
              <h3 className="ModalHeadd">{title}</h3>
            </div>
            <div className="ModalBTNS">
              <button
                className="ModalBtn1"
                onClick={() => setOpen(false)}
                style={{
                  backgroundColor:
                    isPermanentDelete || deleteId ? "#FFDDD1" : "#E1F7E3",
                  color: isPermanentDelete || deleteId ? "#E34444" : "#23BD33",
                }}
              >
                No
              </button>

              <button
                style={{
                  backgroundColor:
                    isPermanentDelete || deleteId ? "#E34444" : "#23BD33",
                }}
                className="ModalBtn2"
                onClick={() => {
                  deleteId ? func2() : func();
                }}
              >
                Yes
              </button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
export default Recycle;
