import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./LogoutModal.css";
import { Link } from "react-router-dom";
import Dummy from "../Images/dummy-user.png";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/reducers/userReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../constants";
import logo from "../Images/Logo.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modall from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const LogoutModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    localStorage.removeItem("superadmintoken");
    await dispatch(setUser(null));
    navigate("/");
  };
  const { user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClosee = () => setOpen(false);
  return (
    <div className="LogoutModal">
      <div className="mainModal">
        <div className="Logo" onClick={handleShow}>
          <img
            style={{
              borderRadius: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
            src={`${baseUrl}/images/${user?.profilePicture}`}
            onError={({ currentTarget }) => {
              currentTarget.src = Dummy;
            }}
            alt="#"
          />
        </div>

        <div className="modalContainer">
          <Modal
            show={show}
            onHide={handleClose}
            animation={false}
            className="custom-modal"
          >
            <div className="devSqur"></div>
            <Modal.Header closeButton>
              <Link to="/AccountSetting" className="MyProfile_link">
                <Modal.Title className="title">My Profile</Modal.Title>
              </Link>
            </Modal.Header>
            <Modal.Body className="detlas">
              <ul>
                <Link to="/AccountSetting" className="AccountSettig">
                  Account Setting
                </Link>
                <li
                  onClick={() => {
                    handleOpen();
                    handleClose();
                  }}
                >
                  Log Out
                </li>
              </ul>
            </Modal.Body>
          </Modal>
        </div>

        <div className="LogoUtImgMain">
          <Modall
            open={open}
            onClose={handleClosee}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="LogoutLastModal">
                  <div className="LogOutIMG">
                    <img src={logo} alt="#" />
                    <h3>Mycentrality</h3>
                  </div>
                  <p>
                    Are you sure you want to <br /> log out
                  </p>
                  <div className="LogOutBTNS">
                    <button onClick={handleClosee} className="LogOutBTNSONe">
                      No
                    </button>
                    <button onClick={handleLogout} className="LogOutBTNSTwo">
                      Yes
                    </button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Modall>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
