import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Notification.css";
import NotificationdataCard from "../notificationCard/NotificationdataCard";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const notifiactionDot = "●";

function OffCanvasExample({ name, ...props }) {
  const [notifications, setNotifications] = useState([]);
  console.log(setNotifications);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  function notcard(val) {
    return (
      <NotificationdataCard
        key={val.id}
        image={val.image}
        name={val.name}
        time={val.createdAt}
        body={val.body}
        dotIcons={notifiactionDot}
        deleteNotification={val.id}
      />
    );
  }

  return (
    <>
      <div className="row notiFication">
        <div onClick={handleShow}>
          <NotificationsNoneOutlinedIcon />
        </div>

        <Offcanvas show={show} onHide={handleClose} {...props}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Notification</Offcanvas.Title>
          </Offcanvas.Header>
          <hr className="Noti-hr" />
          <Offcanvas.Body>
            <div className="notifications-btn">
              <button className="all-notification-btn">All Notification</button>
              <button className="unread-btn">Unread</button>
            </div>
            <h1 className="notification-heading">TODAY</h1>
            {notifications && (
              <div className="notification-map">
                {notifications && notifications.notifications?.map(notcard)}
              </div>
            )}
            <div className="notification-yesterday">
              <h6>Yesterday</h6>
            </div>
            {notifications && (
              <div className="notification-map2">
                {notifications && notifications.notifications?.map(notcard)}
              </div>
            )}

            <div className="notification-clean-btn">
              <button>Clear all</button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

function Example() {
  return (
    <>
      {["end"].map((placement, idx) => (
        <OffCanvasExample key={idx} placement={placement} name={placement} />
      ))}
    </>
  );
}

export default Example;
