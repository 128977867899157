import React from "react";
import "./Dashboard.css";
import { GoPeople } from "react-icons/go";
import { BsPersonCheck } from "react-icons/bs";
import { TbDeviceImac } from "react-icons/tb";
import axios from "axios";
import { useGetTotalDistrictSchoolsCountQuery } from "../../redux/APIs";
import { useSelector } from "react-redux";

import { baseUrl } from "../../constants";

import { Link } from "react-router-dom";
axios.defaults.baseURL = baseUrl;

const Total = () => {
  const { user } = useSelector((state) => state.auth);
  const { data } = useGetTotalDistrictSchoolsCountQuery({
    districtId: user?.districtId,
    skip: !user?.districtId,
  });

  return (
    <div className="mains">
      <div className="tableScore">
        <div className="school">
          <span>
            <GoPeople />
          </span>
          <div className="score">
            <p>Total District Schools</p>
            <h3>{data?.liveCounts?.totalSchools}</h3>
          </div>
        </div>
        <div className="school line">
          <span>
            <BsPersonCheck />
          </span>
          <Link to="/DistrictAdmins" className="score">
            <p>Total District Admins</p>
            <h3>{data?.liveCounts?.totalAdmins}</h3>
          </Link>
        </div>
        <div className="school">
          <span>
            <BsPersonCheck />
          </span>

          <div className="score">
            <p>Total Superintendents</p>
            <h3>{data?.liveCounts?.totalSuperintendents}</h3>
          </div>
        </div>
      </div>
      <div className="tableScore sectableScore">
        <div className="school">
          <span>
            <GoPeople />
          </span>

          <div className="score">
            <p>Total District Teacher</p>
            <h3>{data?.liveCounts?.totalTeachers}</h3>
          </div>
        </div>
        <div className="school line">
          <span>
            <BsPersonCheck />
          </span>
          <div className="score">
            <p>Total District Parents</p>
            <h3>{data?.liveCsounts?.totalParents}</h3>
          </div>
        </div>
        <div className="school">
          <span>
            <TbDeviceImac />
          </span>
          <div className="score">
            <p>Total District Students</p>
            <h3>{data?.liveCounts?.totalStudents}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Total;
