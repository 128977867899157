import React, { useState, useEffect } from "react";
import "./NewFeed.css";
import Dropdown from "react-bootstrap/Dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  useAddNewsFeedbackMutation,
  useGetNewsFeedbackQuery,
  useEditNewsFeedbackMutation,
  useGetTotalDistrictSchoolsQuery,
  useDeleteNewsFeedbackMutation,
} from "../../redux/APIs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  IoArrowBack,
  IoCaretBackOutline,
  IoCaretForwardOutline,
} from "react-icons/io5";

const NewFeed = () => {
  const [show, setShow] = useState(false);
  const [addNewsFeedbackHandler] = useAddNewsFeedbackMutation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [titleWordCount, setTitleWordCount] = useState(0);
  const [descriptionWordCount, setDescriptionWordCount] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const { data: newsFeedbacks } = useGetNewsFeedbackQuery(
    { adminId: user?.id },
    { skip: !user?.id }
  );
  const [editNewsFeedbackHandler] = useEditNewsFeedbackMutation();
  const [editId, setEditId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;
  console.log(titleWordCount);
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const handleWordCount = (text, setWordCount) => {
    const words = text.trim().split(/\s+/).filter(Boolean);
    setWordCount(words.length);
  };

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    handleWordCount(newTitle, setTitleWordCount);
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setDescription(newDescription);
    handleWordCount(newDescription, setDescriptionWordCount);
  };

  const { data } = useGetTotalDistrictSchoolsQuery(
    { districtId: user?.districtId },
    { skip: !user?.districtId }
  );

  const [schoolId, setSchoolId] = useState("");
  const handlePublish = async () => {
    try {
      if (!editId) {
        const res = await addNewsFeedbackHandler({
          title,
          description,
          date,
          superAdminId: user?.id,
          schoolid: Number(schoolId),
        });

        if (res?.error)
          return toast.error(
            res?.error?.data?.error || res?.error?.data?.message
          );
      } else {
        const data = {
          title,
          description,
          date,
          schoolid: Number(schoolId),
        };

        const res = await editNewsFeedbackHandler({ feedbackId: editId, data });
        if (res?.error)
          return toast.error(
            res?.error?.data?.error || res?.error?.data?.message
          );
      }

      toast.success(`Feedback ${editId ? "Edited" : "Added"}`);
      setTitle("");
      setDescription("");
      setDate("");
      setEditId("");
      setSchoolId("");
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (editId && newsFeedbacks) {
      setShow(true);
      const findEdit = newsFeedbacks?.feedbacks?.find(
        (feedback) => feedback?.id === editId
      );
      setTitle(findEdit?.title || "");
      setDescription(findEdit?.description || "");
      setDate(new Date(findEdit?.date)?.toISOString()?.split("T")[0]);
    } else {
      setShow(false);
    }
  }, [editId, newsFeedbacks]);

  const indexOfLastFeedback = (currentPage + 1) * itemsPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - itemsPerPage;
  const currentFeedbacks =
    newsFeedbacks?.feedbacks.slice(indexOfFirstFeedback, indexOfLastFeedback) ||
    [];
  const pageCount = Math.ceil(
    (newsFeedbacks?.feedbacks.length || 0) / itemsPerPage
  );

  const [deleteNewsFeedback] = useDeleteNewsFeedbackMutation();
  const handleDeleteNewsFeedback = async (feedbackId) => {
    const toastId = toast.loading("Deleting...");
    try {
      const res = await deleteNewsFeedback(feedbackId);

      if (res?.error)
        return toast.error(res?.error?.data?.message || "Failed to delete", {
          id: toastId,
        });
      toast.success("Deleted Successfully", {
        id: toastId,
      });
      setEditId("");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="NewFeedMain">
      {!show ? (
        <div>
          <div className="NewFeedTop">
            <h2>Schools feed</h2>
            <button onClick={() => setShow(true)}>Add new feed</button>
          </div>
          {currentFeedbacks.map((feedback) => (
            <div key={feedback?.id}>
              <div className="FeedBody">
                <div className="FeedBodyTop">
                  <h4>{feedback?.title}</h4>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="Mini-Main-Div">
                        <Dropdown.Item onClick={() => setEditId(feedback?.id)}>
                          <p>Edit</p>
                          <FiEdit3 />
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDeleteNewsFeedback(feedback?.id)}
                        >
                          <p>Delete</p>
                          <RiDeleteBin6Line />
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="FeedText">
                  <p>“ {feedback?.description} “</p>
                </div>
                <div className="FeedDate">
                  <p>
                    School Name: <span>{feedback?.School?.name}</span>
                  </p>
                  <p>
                    Date: <span>{feedback?.date?.split("T")[0]}</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
          <ReactPaginate
            previousLabel={<IoCaretBackOutline />}
            nextLabel={<IoCaretForwardOutline />}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <div className="AddNewFEED">
          <div className="NewFeedTop">
            <h2>
              <IoArrowBack
                onClick={() => {
                  setShow(false);
                  setEditId("");
                }}
              />
              Add new feed
            </h2>
          </div>
          <input
            value={title}
            onChange={handleTitleChange}
            placeholder="Title"
          />
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Description"
          />
          <div className="WordCount">{descriptionWordCount}/5000</div>
          <div className="FeedLastSec">
            <div className="FeedAddDate">
              <div>
                <label>Date</label>
                <input
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  type="date"
                />
              </div>
              <div>
                <label>District School Name</label>
                <select onChange={(e) => setSchoolId(e.target.value)}>
                  <option value={""}>Select School</option>
                  {data?.schools?.map((school) => (
                    <option key={school?.id} value={school?.id}>
                      {school?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <button onClick={handlePublish}>Publish</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewFeed;
