import React, { useState, useEffect } from "react";
import "./Login.css";
import Logo from "../Images/Logo.png";
import SideImg1 from "../Images/image 9.png";
import SideImg2 from "../Images/Group 1000006269.png";
import SideImg3 from "../Images/cloud-computing.png";
import { LuMail } from "react-icons/lu";
import { MdLockOutline } from "react-icons/md";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { setUser } from "../../redux/reducers/userReducer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../constants";
axios.defaults.baseURL = baseUrl;
const images = [SideImg1, SideImg2, SideImg3];

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { user } = useSelector((state) => state.auth);
  console.log(user);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const getDotClass = (index) => {
    return index === currentImageIndex ? "dits-white" : "dits-blue";
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignup = async () => {
    const toastId = toast.loading("Loading...");
    try {
      const { data } = await axios.post(
        `/auth/signin`,
        {
          email,
          password,
          role: "superadmin",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(
        `Welcome back ${data.user?.firstName} ${data.user?.lastName}`,
        {
          id: toastId,
        }
      );

      localStorage.setItem("superadmintoken", data.accessToken);

      await dispatch(setUser(data.user));
      navigate(`/AdminHome`);
      window.location.reload();
    } catch (error) {
      console.log(error, "this is error");
      if (error?.response?.data?.details) {
        toast.error(
          error?.response?.data?.details[0]?.detail ||
            error?.response?.data?.details[0]?.message,
          {
            id: toastId,
          }
        );
      } else {
        toast.error(
          error?.response?.data?.error || error?.response?.data?.msg,
          {
            id: toastId,
          }
        );
      }
    }
  };

  return (
    <div className="Addsignup-main">
      <div className="Addsignup-main-child-1">
        <div className="Addsignup-main-child-1-logo">
          <div className="LogoSuper">
            <img src={Logo} alt="signinimg" />
          </div>
          <h2>MyCentrality</h2>
        </div>
        <div className="Addsignup-main-child-1-img">
          <img src={images[currentImageIndex]} alt="img" />
        </div>
        <div className="Addsignup-main-child-1-taxt">
          <h2>MyCentrality Multipurpose Dashboard</h2>
          <p>Everything you need in an easily customizable dashboard.</p>
        </div>
        <div className="Addsignup-main-child-1-dits">
          {images.map((_, index) => (
            <div key={index} className={getDotClass(index)}></div>
          ))}
        </div>
      </div>
      <div className="Addsignup-main-child-2">
        <div className="SuperAdminAMin">
          <div className="Addsignup-main-child-2-title">
            <h2>Sign In with My Centrality</h2>
            <p>Welcome back! Please enter your details</p>
          </div>

          <div className="SuperSignIN">
            <div className="LoginSuper">
              <LuMail />
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="Email"
              />
            </div>
            <div className="LoginSuper1">
              <div className="LoginSupTo">
                <MdLockOutline />
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div onClick={togglePasswordVisibility} className="eyeIcon">
                {passwordVisible ? <FiEye /> : <FiEyeOff />}
              </div>
            </div>
            <div className="SuperForgot">
              <div className="SuperFor1">
                <input type="checkbox" />
                <label>Remember me</label>
              </div>
              <p>Forgot Password?</p>
            </div>
            <button onClick={handleSignup} className="LoginContinue">
              Continue
            </button>
            <Link to="/SignUp" className="SignUpBtn">
              <button>SignUp</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
