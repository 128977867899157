import React, { useState } from "react";
import {
  IoArrowBackSharp,
  IoCaretForwardOutline,
  IoHomeSharp,
  IoSearchOutline,
} from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetSchoolQuery,
  useUpdateStudentPermissionsMutation,
} from "../../redux/APIs";

const StudentPer = () => {
  const navigate = useNavigate();

  const params = useParams();

  function handleClick() {
    navigate(`/SchoolTabs/${params.schoolId}`);
  }

  function handlenavigate() {
    navigate("/AdminHome");
  }
  const { data } = useGetSchoolQuery(
    { schoolId: params.schoolId },
    { skip: !params.schoolId }
  );

  const initialState = {
    DailyAndWeeklySchedules: true,
    ScheduleChanges: true,
    SpecialEvents: true,
    ViewPermissions: true,
    DownloadPermissions: true,
    UpcomingAssignments: true,
    EditPermissions: true,
    StatusUpdates: true,
    CurrentGrades: true,
    AssignmentDetails: true,
    ProgressReports: true,
    DailyAttendance: true,
    AbsenceDetails: true,
    TardyRecords: true,
    ReportCards: true,
    Transcripts: true,
    Notifications: true,
    Messaging: true,
    Announcements: true,
    EventUpdates: true,
    ExternalAppsIntegration: true,
    SingleSignOn: true,
    RealTimeGPACalculation: true,
    ActivityListings: true,
    HealthRecords: true,
    BehaviorRecords: true,
    EditProfile: true,
  };

  const [settings, setSettings] = useState(initialState);

  const handleToggle = (key) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
  };

  const [handleUpdateStudentPermissionsHandler] =
    useUpdateStudentPermissionsMutation();
  const handleUpdateStudentPermissions = async () => {
    try {
      const res = await handleUpdateStudentPermissionsHandler({
        ...settings,
        schoolId: params.schoolId,
      });
      if (res?.error)
        return toast.error(res?.error?.data?.message || "Something went wrong");

      toast.success("Permissions updated successfully");
    } catch (error) {
      console.log(error, "This is error");
    }
  };
  return (
    <div>
      <div className="SchoolTop">
        <div className="SchoolHome">
          <div className="SchoolHomeIcon" onClick={handlenavigate}>
            <IoHomeSharp />
            <span>Home</span>
          </div>
          <IoCaretForwardOutline />
          <div className="SchoolHomeIcon">
            <IoIosPeople />
            <span>School</span>
          </div>
        </div>
        <hr />
        <div className="SchoolName">
          <h2>{data?.school?.name}</h2>
        </div>
      </div>
      <div className="SchoolMTabs">
        <div className="AddTeacherBody">
          <div className="AddTeacherTop">
            <IoArrowBackSharp onClick={handleClick} />
            <h3>Student Permissions</h3>
          </div>
          <div className="TeacherPermission">
            <div className="SchoolATabSearch1">
              <IoSearchOutline />
              <input placeholder="Search" />
            </div>
            <h4>Schedules:</h4>
            <div className="TeacherFlex">
              <p>
                Daily and Weekly Schedules: Students can view their class
                schedules, including class times, room numbers, and teachers’
                names.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="dailyWeeklySchedules"
                  checked={settings.DailyAndWeeklySchedules}
                  onChange={() => handleToggle("DailyAndWeeklySchedules")}
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Schedule Changes: Real-time updates reflect any schedule
                changes, such as room changes or updated class times.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="scheduleChanges"
                  checked={settings.ScheduleChanges}
                  onChange={() => handleToggle("ScheduleChanges")}
                />
              </Form>
            </div> */}
            <div className="TeacherFlex">
              <p>
                Special Events: Information about school events, holidays, and
                special schedules (e.g., exam weeks) is available.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="specialEvents"
                  checked={settings.SpecialEvents}
                  onChange={() => handleToggle("SpecialEvents")}
                />
              </Form>
            </div>
            <hr />

            <h4>Course Materials:</h4>
            <div className="TeacherFlex">
              <p>
                View Permissions: Students can access and view all course
                materials, including lectures, readings, and assignments.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="viewPermissions"
                  checked={settings.ViewPermissions}
                  onChange={() => handleToggle("ViewPermissions")}
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Download Permissions: Students can download course materials for
                offline use.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="downloadPermissions"
                  checked={settings.DownloadPermissions}
                  onChange={() => handleToggle("DownloadPermissions")}
                />
              </Form>
            </div> */}
            <hr />

            <h4>Assessments Permission:</h4>
            {/* <div className="TeacherFlex">
              <p>
                Upcoming Assignments: Students can see a list of upcoming
                assignments, along with due dates and specific instructions.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="upcomingAssignments"
                  checked={settings.UpcomingAssignments}
                  onChange={() => handleToggle("UpcomingAssignments")}
                />
              </Form>
            </div> */}
            <div className="TeacherFlex">
              <p>
                Edit Permissions: Students can edit their submissions before the
                due date.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="editPermissions"
                  checked={settings.EditPermissions}
                  onChange={() => handleToggle("EditPermissions")}
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Status Updates: Track the status of submissions, see if
                assignments have been graded, and view teacher feedback.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="statusUpdates"
                  checked={settings.StatusUpdates}
                  onChange={() => handleToggle("StatusUpdates")}
                />
              </Form>
            </div> */}
            <hr />

            <h4>Access to Grades:</h4>
            <div className="TeacherFlex">
              <p>
                Current Grades: Students can view their grades for each class in
                real-time, including individual assignment scores and overall
                course grades.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="currentGrades"
                  checked={settings.CurrentGrades}
                  onChange={() => handleToggle("CurrentGrades")}
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Assignment Details: Detailed information about each assignment,
                such as the due date, total points possible, and specific
                teacher comments, is accessible.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="assignmentDetails"
                  checked={settings.AssignmentDetails}
                  onChange={() => handleToggle("AssignmentDetails")}
                />
              </Form>
            </div> */}
            {/* <div className="TeacherFlex">
              <p>
                Progress Reports: Interim progress reports provide insight into
                student performance before final grades are posted.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="progressReports"
                  checked={settings.ProgressReports}
                  onChange={() => handleToggle("ProgressReports")}
                />
              </Form>
            </div> */}
            <hr />
            {/* <h4>Attendance Records:</h4>
            <div className="TeacherFlex">
              <p>
                Daily Attendance: Students can see their daily attendance
                records, including each class period attended or missed.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="dailyAttendance"
                  checked={settings.DailyAttendance}
                  onChange={() => handleToggle("DailyAttendance")}
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Absence Details: Information about excused and unexcused
                absences, including reasons and dates, is available.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="absenceDetails"
                  checked={settings.AbsenceDetails}
                  onChange={() => handleToggle("AbsenceDetails")}
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Tardy Records: Records of tardies (late arrivals) are
                accessible, allowing students to track their punctuality.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="tardyRecords"
                  checked={settings.TardyRecords}
                  onChange={() => handleToggle("TardyRecords")}
                />
              </Form>
            </div>
            <hr /> */}

            {/* <h4>Report Card and Transcripts</h4>
            <div className="TeacherFlex">
              <p>
                Report Cards: End-of-term report cards showing final grades for
                each class and teachers’ comments.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="reportCards"
                  checked={settings.ReportCards}
                  onChange={() => handleToggle("ReportCards")}
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Transcripts: Access to unofficial transcripts providing a
                comprehensive record of the student’s academic history.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="transcripts"
                  checked={settings.Transcripts}
                  onChange={() => handleToggle("Transcripts")}
                />
              </Form>
            </div>
            <hr /> */}

            {/* <h4>Communication</h4>
            <div className="TeacherFlex">
              <p>
                Notifications: Students receive notifications about important
                events, such as assignment deadlines, schedule changes, and
                school announcements.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="notifications"
                  checked={settings.Notifications}
                  onChange={() => handleToggle("Notifications")}
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Messaging: A built-in messaging system allows students to
                communicate with teachers and guidance counselors.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="messaging"
                  checked={settings.Messaging}
                  onChange={() => handleToggle("Messaging")}
                />
              </Form>
            </div>
            <hr /> */}

            <h4>Noticeboard:</h4>
            <div className="TeacherFlex">
              <p>
                Announcements: Access to a digital noticeboard for school-wide
                and class-specific announcements.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="announcements"
                  checked={settings.Announcements}
                  onChange={() => handleToggle("Announcements")}
                />
              </Form>
            </div>
            {/* <div className="TeacherFlex">
              <p>
                Event Updates: Information on upcoming events, deadlines, and
                important notices.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="eventUpdates"
                  checked={settings.EventUpdates}
                  onChange={() => handleToggle("EventUpdates")}
                />
              </Form>
            </div> */}
            {/* <hr />
            <h4>App Access</h4>
            <div className="TeacherFlex">
              <p>
                External Apps Integration: Access to integrated educational apps
                like Toppr, Khabri, and other third-party applications used by
                the school.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="externalAppsIntegration"
                  checked={settings.ExternalAppsIntegration}
                  onChange={() => handleToggle("ExternalAppsIntegration")}
                />
              </Form>
            </div>
            <div className="TeacherFlex">
              <p>
                Single Sign-On: Seamless access to external educational apps
                through single sign-on functionality.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="singleSignOn"
                  checked={settings.SingleSignOn}
                  onChange={() => handleToggle("SingleSignOn")}
                />
              </Form>
            </div> */}
            <hr />

            {/* <h4>GPA Calculator</h4>
            <div className="TeacherFlex">
              <p>
                Real-Time GPA Calculation: A tool for calculating current and
                projected GPA based on grades.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="realTimeGPACalculation"
                  checked={settings.RealTimeGPACalculation}
                  onChange={() => handleToggle("RealTimeGPACalculation")}
                />
              </Form>
            </div>
            <hr /> */}

            {/* <h4>Extracurricular Activities:</h4>
            <div className="TeacherFlex">
              <p>
                Activity Listings: Information about available extracurricular
                activities, including clubs, sports, and special interest
                groups.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="activityListings"
                  checked={settings.ActivityListings}
                  onChange={() => handleToggle("ActivityListings")}
                />
              </Form>
            </div>
            <hr /> */}

            <h4>Medical Information:</h4>
            <div className="TeacherFlex">
              <p>
                Health Records: Access to personal health records maintained by
                the school, including immunizations and medical alerts.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="healthRecords"
                  checked={settings.HealthRecords}
                  onChange={() => handleToggle("HealthRecords")}
                />
              </Form>
            </div>
            <hr />

            <h4>Discipline Records:</h4>
            <div className="TeacherFlex">
              <p>
                Behavior Records: View records of any disciplinary actions,
                including infractions, consequences, and notes from
                administrators.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="behaviorRecords"
                  checked={settings.BehaviorRecords}
                  onChange={() => handleToggle("BehaviorRecords")}
                />
              </Form>
            </div>
            <hr />
            {/* <h4>Profile And Settings:</h4>
            <div className="TeacherFlex">
              <p>
                Edit Profile: Students can update their profile information,
                such as their contact details and profile picture.
              </p>
              <Form>
                <Form.Check
                  type="switch"
                  id="editProfile"
                  checked={settings.EditProfile}
                  onChange={() => handleToggle("EditProfile")}
                />
              </Form>
            </div>
            <hr /> */}

            <div className="CreateButton">
              <button
                className="CreateButton1"
                onClick={() => setSettings(initialState)}
              >
                Reset
              </button>
              <button
                className="CreateButton2"
                onClick={handleUpdateStudentPermissions}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentPer;
