import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../redux/reducers/userReducer";
import { userApis } from "./APIs";

const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [userApis.reducerPath]: userApis.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApis.middleware),
});

export default store;
