import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BsSliders } from "react-icons/bs";
import "./Filter.css";
import { CiFilter } from "react-icons/ci";
import Accordion from "react-bootstrap/Accordion";
function FilterModal() {
  return (
    <div className="FilterAll1">
      <div className="FilterAllSvg">
        <BsSliders />
      </div>
      <div className="COLORFILTER">
        <DropdownButton id="dropdown-basic-button" title="Filters">
          <Dropdown>
            <div className="FilterTOOP">
              <h2>Filter</h2>
              <CiFilter />
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="FilterNAme">
                    <h3>Student</h3>
                  </div>
                </Accordion.Header>

                <Accordion.Body>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>Name</label>
                    </div>
                    <button>32</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>Student ID</label>
                    </div>
                    <button>14</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>Grade Level</label>
                    </div>

                    <button>32</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>SSID</label>
                    </div>
                    <button>32</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>Admission</label>
                    </div>
                    <button>32</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>Email</label>
                    </div>
                    <button>32</button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="FilterNAme">
                    <h3>School</h3>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>High School</label>
                    </div>
                    <button>32</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>High School</label>
                    </div>
                    <button>32</button>
                  </div>
                  <div className="FilterINPUTS">
                    <div className="FilterCheckBOX">
                      <input type="checkbox" />
                      <label>Grade Level</label>
                    </div>
                    <button>32</button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="FilterNAme">
                    <h3> Range</h3>
                  </div>
                </Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="FilterNAme">
                    <h3> Language</h3>
                  </div>
                </Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* ......................... */}
            <div className="FilterLSTbtns">
              <button>Done</button>
              <button>Reset</button>
            </div>
          </Dropdown>
        </DropdownButton>
      </div>
    </div>
  );
}
export default FilterModal;
