import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import Login from "./Components/Login/Login";
import Importdata from "./Components/ImportData/Importdata";
import Dashboard from "./Components/Dashboard/Dashboard";
import Navbar from "./Components/NavBar/Navbar";
import SchoolTabs from "./Components/SchoolTabs/SchoolTabs";
import AddAdmin from "./Components/AddNewAdmin/AddAdmin";
import AddTeacher from "./Components/AddNewTeacher/AddTeacher";
import StudentPer from "./Components/AddStudentPer/StudentPer";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { baseUrl } from "./constants";
import { useDispatch } from "react-redux";
import { setUser } from "./redux/reducers/userReducer";
import { useSelector } from "react-redux";
import ProtectedRoute from "./Components/ProtectedRoutes/ProtectedRoute";
import SignUpPage from "./Components/SignUp/SignUpPage";
import NewFeed from "./Components/AddNewFeed/NewFeed";
import Credential from "./Components/EditCredential/Credential";
import Setting from "./Components/AccountSetting/Setting";
import DistrictAdmin from "./Components/DistrictAdmins/DistrictAdmin";
import Recycle from "./Components/RecycleBin/Recycle";
import AddAdminn from "./Components/DistrictAdmins/AddNewAdmin/AddAdminn";
axios.defaults.baseURL = baseUrl;

function App() {
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`/loginprofile/getuser`, {
          headers: {
            accessToken: localStorage.getItem("superadmintoken"),
          },
        });
        dispatch(setUser(data.dataValues));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [dispatch]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              user={!user}
              redirect={"/AdminHome"}
              loading={loading}
            >
              <Login />
            </ProtectedRoute>
          }
        />

        <Route
          path="/SignUp"
          element={
            <ProtectedRoute
              user={!user}
              redirect={"/AdminHome"}
              loading={loading}
            >
              <SignUpPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AdminHome"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<DashboardWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ImportData"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<ImportdataWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/SchoolTabs/:schoolId"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<SchoolTabsWithNavbar />} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AddNewAdmin/:schoolId"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<AddAdminWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddNewTeacher/:schoolId"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<AddTeacherWithNavbar />} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/StudentPer/:schoolId"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<StudentPerWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/EditCredential/:schoolId/:adminId"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<CredentialWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AccountSetting"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<SettingWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/NewFeed"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<NewFeedWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/RecycleBin"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<RecycleWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/DistrictAdmins"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<DistrictAdminWithNavbar />} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/AddDistrictAdmin"
          element={
            <ProtectedRoute user={user} loading={loading}>
              <PrivateRoute component={<AddAdminnWithNavbar />} />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Toaster />
    </div>
  );
}

function PrivateRoute({ component }) {
  const isAuthenticated = true;
  if (isAuthenticated) {
    return component;
  } else {
    return <Navigate to="/Login" replace />;
  }
}

function DashboardWithNavbar() {
  return (
    <>
      <Navbar />
      <Dashboard />
    </>
  );
}

function ImportdataWithNavbar() {
  return (
    <>
      <Navbar />
      <Importdata />
    </>
  );
}

function SchoolTabsWithNavbar() {
  return (
    <>
      <Navbar />
      <SchoolTabs />
    </>
  );
}
function AddAdminWithNavbar() {
  return (
    <>
      <Navbar />
      <AddAdmin />
    </>
  );
}
function AddTeacherWithNavbar() {
  return (
    <>
      <Navbar />
      <AddTeacher />
    </>
  );
}

function StudentPerWithNavbar() {
  return (
    <>
      <Navbar />
      <StudentPer />
    </>
  );
}

function CredentialWithNavbar() {
  return (
    <>
      <Navbar />
      <Credential />
    </>
  );
}
function SettingWithNavbar() {
  return (
    <>
      <Navbar />
      <Setting />
    </>
  );
}

function NewFeedWithNavbar() {
  return (
    <>
      <Navbar />
      <NewFeed />
    </>
  );
}

function RecycleWithNavbar() {
  return (
    <>
      <Navbar />
      <Recycle />
    </>
  );
}

function DistrictAdminWithNavbar() {
  return (
    <>
      <Navbar />
      <DistrictAdmin />
    </>
  );
}

function AddAdminnWithNavbar() {
  return (
    <>
      <Navbar />
      <AddAdminn />
    </>
  );
}

export default App;
